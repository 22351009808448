import { Box, Button, Icon, IconButton, Stack, Typography } from '@mui/material';
import { ApplicationShort } from '@schooly/api';
import { DATE_FORMAT_RANGE_MOMENT } from '@schooly/constants';
import {
  CancelIcon,
  ConvertedIcon,
  InformationIcon,
  ModalContent,
  PlusIcon,
  theme,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { formatApplicationDate } from '@schooly/utils/application-helpers';
import { getNationalitiesLabelIds } from '@schooly/utils/get-nationalities-label-ids';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ModalEmpty } from 'apps/web/src/components/uikit-components/Modal/ModalEmpty';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicationsTab } from 'apps/web/src/context/applications/ApplicationsContext';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useApplication } from 'apps/web/src/context/applications/useApplication';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getUserFullName } from 'apps/web/src/helpers/users';
import moment from 'moment';
import { FC, PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import Tag from '../../../components/ui/Tag';
import { GridCell, GridRowName } from '../../../components/uikit-components/Grid/Grid';
import { ModalFooter, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { Table, TableCell } from '../../../components/uikit-components/Table/Table';
import { HOVER_CLASS_NAME } from '../../../context/table/tableHover/WithTableHover';
import { ManageGroupModalTooltip } from '../../Groups/ManageGroupModal/ManageGroupModalTooltip';
import { ApplicationNote } from './ApplicationNote';

export const HOVER_ICON_CLASS_NAME = 'hoverIcon';

interface ApplicationListContentProps {
  applications?: ApplicationShort[];
  applicationsFetching: boolean;
}

export const ApplicationListContent: FC<PropsWithChildren<ApplicationListContentProps>> = ({
  applications,
  applicationsFetching,
  children,
}) => {
  const { $t } = useIntl();

  const { isApplicationManager, activeTab } = useApplication();

  const navigate = useNavigate();
  const location = useLocation();

  const { formatMessage } = useIntl();

  const columns: TableCell[] = [
    {
      id: 'applications-Applicant',
      renderContent: () => (
        <GridCell
          pl={2}
          py={1}
          sx={{
            width: 260,
          }}
        >
          <Typography>
            <FormattedMessage id="applications-Applicant" />
          </Typography>
        </GridCell>
      ),
    },
    {
      id: 'applications-StartingDate',
      renderContent: () => (
        <GridCell
          py={1}
          sx={{
            width: 90,
          }}
        >
          <Typography>
            <FormattedMessage id="applications-StartingDate" />
          </Typography>
        </GridCell>
      ),
    },
    {
      id: 'userType-child-plural',
      renderContent: () => (
        <GridCell
          pl={2}
          py={1}
          sx={{
            width: 260,
          }}
        >
          <Typography>
            <FormattedMessage id="userType-child-plural" />
          </Typography>
        </GridCell>
      ),
    },
    {
      id: 'dob',
      renderContent: () => (
        <GridCell
          py={1}
          sx={{
            width: 150,
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>
              <FormattedMessage id="date-of-birthday" />
            </Typography>
            <ManageGroupModalTooltip
              text={
                <Typography color="primary.main">
                  <FormattedMessage id="applications-AgeCalculating" />
                </Typography>
              }
              arrow
            >
              <IconButton inverse>
                <InformationIcon />
              </IconButton>
            </ManageGroupModalTooltip>
          </Stack>
        </GridCell>
      ),
    },
    {
      id: 'peopleDetail-Nationality',
      renderContent: () => (
        <GridCell py={1}>
          <Typography>
            <FormattedMessage id="peopleDetail-Nationality" />
          </Typography>
        </GridCell>
      ),
    },
    {
      id: 'Note',
      renderContent: () => <GridCell py={1}></GridCell>,
    },
  ];

  const rows = useMemo(() => {
    if (!applications?.length) {
      return [];
    }

    return applications.flatMap((application) => {
      const parent = application.adults.find((p) => Boolean(p.primary));
      const children = application.children;
      const rejected = application.status === 'rejected';
      const converted = application.status === 'converted';

      return children.map((child, index) => {
        const firstEl = index === 0;
        const lastEl = index === children.length - 1;
        const singleRow = firstEl && lastEl;

        const getPadding = () => {
          if (firstEl && !singleRow) {
            return { mb: 1, mt: 3.5 };
          }
          if (lastEl && !singleRow) {
            return { mb: 3.5, mt: 1 };
          }

          return { my: 0.5 };
        };

        const yearsOld = moment(child.preferred_start_date).diff(
          moment(child.date_of_birth),
          'years',
        );

        const cellCommonProps = {
          '&&&&&&': {
            borderColor: lastEl || singleRow ? undefined : 'transparent',
          },
        };

        const padding = getPadding();

        const nationalities = getNationalitiesLabelIds(child.nationalities)
          .map((id) => formatMessage({ id }))
          .join(', ');

        const cellArr: TableCell[] = [
          {
            id: 'Applicant',
            renderContent: (props) => (
              <GridCell
                noVerticalPadding
                sx={{
                  maxWidth: 260,
                  height: singleRow ? 84 : 48,
                  ...cellCommonProps,
                }}
                {...props}
              >
                {parent && index === 0 && (
                  <GridRowName sx={padding}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      pl={rejected || converted ? 3 : 0}
                      gap={rejected || converted ? 1.5 : 0}
                    >
                      {rejected && (
                        <ManageGroupModalTooltip
                          text={
                            <Typography sx={(theme) => ({ color: theme.palette.common.grey2 })}>
                              <FormattedMessage id="applications-Status-Rejected" />
                            </Typography>
                          }
                          sx={{ maxHeight: 18, display: 'flex', alignItems: 'center' }}
                          offset={[0, -10]}
                          width={'100%'}
                        >
                          <Icon
                            className={HOVER_ICON_CLASS_NAME}
                            sx={(theme) => ({
                              color: theme.palette.common.grey,
                            })}
                          >
                            <CancelIcon />
                          </Icon>
                        </ManageGroupModalTooltip>
                      )}

                      {converted && (
                        <ManageGroupModalTooltip
                          text={
                            <Typography sx={(theme) => ({ color: theme.palette.common.grey2 })}>
                              <FormattedMessage id="applications-Status-Converted" />
                            </Typography>
                          }
                          sx={{ maxHeight: 18, display: 'flex', alignItems: 'center' }}
                          offset={[0, -10]}
                          width={'100%'}
                        >
                          <Icon
                            className={HOVER_ICON_CLASS_NAME}
                            sx={(theme) => ({
                              color: theme.palette.common.grey,
                            })}
                          >
                            <ConvertedIcon />
                          </Icon>
                        </ManageGroupModalTooltip>
                      )}

                      <Box sx={{ minWidth: 0 }}>
                        <TypographyWithOverflowHint variant="h3">
                          {getUserFullName(parent)}
                        </TypographyWithOverflowHint>
                      </Box>
                    </Stack>
                  </GridRowName>
                )}
              </GridCell>
            ),
          },
          {
            id: 'Starting date',
            renderContent: (props) => (
              <GridCell
                noVerticalPadding
                sx={{
                  ...cellCommonProps,
                  minWidth: 90,
                }}
                {...props}
              >
                <GridRowName sx={padding}>
                  <Typography>{formatApplicationDate(child.preferred_start_date)}</Typography>
                </GridRowName>
              </GridCell>
            ),
          },
          {
            id: 'Children',
            renderContent: (props) => (
              <GridCell
                noVerticalPadding
                sx={{
                  maxWidth: 260,
                  ...cellCommonProps,
                }}
                {...props}
              >
                <GridRowName sx={padding}>
                  <Box sx={{ maxWidth: 240 }}>
                    <TypographyWithOverflowHint variant="h3">
                      {getUserFullName(child)}
                    </TypographyWithOverflowHint>
                  </Box>
                </GridRowName>
              </GridCell>
            ),
          },
          {
            id: 'DOB',
            renderContent: (props) => (
              <GridCell
                noVerticalPadding
                sx={{
                  ...cellCommonProps,
                }}
                {...props}
              >
                <GridRowName sx={padding}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography>
                      {moment(child.date_of_birth).format(DATE_FORMAT_RANGE_MOMENT)}
                    </Typography>
                    <Tag>{`${yearsOld} y.o`}</Tag>
                  </Stack>
                </GridRowName>
              </GridCell>
            ),
          },
          {
            id: 'Nationality',
            renderContent: (props) => (
              <GridCell
                noVerticalPadding
                sx={{
                  maxWidth: 150,
                  ...cellCommonProps,
                }}
                {...props}
              >
                <GridRowName sx={padding}>
                  <TypographyWithOverflowHint>{nationalities}</TypographyWithOverflowHint>
                </GridRowName>
              </GridCell>
            ),
          },
          {
            id: 'Note',
            renderContent: (props) => (
              <GridCell noVerticalPadding sx={cellCommonProps} {...props}>
                <GridRowName sx={padding}>
                  {!!child.notes && <ApplicationNote text={child.notes} />}
                </GridRowName>
              </GridCell>
            ),
          },
        ];
        return {
          id: child.id,
          multiRowHoverId: application.id,
          cells: cellArr,
          props: () => ({
            onClick: () => {
              navigate(application.id, { state: location.state });
            },
            sx: {
              cursor: 'pointer',
            },
          }),
        };
      });
    });
  }, [applications, formatMessage, location.state, navigate]);

  const emptyModalContent = useMemo(
    () => (
      <Stack sx={{ pt: (theme) => theme.spacing(5) }}>
        <ModalEmpty width="300px">
          <Box sx={{ width: activeTab === ApplicationsTab.Open ? 280 : 300 }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: 'center',
              }}
              color="text.primary"
            >
              <FormattedMessage
                id="applications-EmptyList"
                values={{
                  applicationStatus:
                    activeTab === ApplicationsTab.Open
                      ? formatMessage({ id: 'applications-Status-Open' }).toLowerCase()
                      : activeTab === ApplicationsTab.Rejected
                      ? formatMessage({ id: 'applications-Status-Rejected' }).toLowerCase()
                      : $t({ id: 'applications-Status-Converted' }).toLowerCase(),
                }}
              />
            </Typography>
          </Box>
        </ModalEmpty>
      </Stack>
    ),
    [formatMessage, activeTab, $t],
  );

  const getHoverStyles = ({ hoverColor, iconColor }: { hoverColor: string; iconColor: string }) => {
    return {
      [`.${HOVER_CLASS_NAME}`]: {
        backgroundColor: `${hoverColor} !important`,
        'div, p, th, a, &.MuiTableCell-root': {
          color: `${theme.palette.primary.main} !important`,
        },
        [`.${HOVER_ICON_CLASS_NAME}`]: {
          color: `${iconColor} !important`,
        },
      },
    };
  };

  return (
    <>
      <ModalMain sx={{ paddingTop: 0 }}>
        <ModalContent
          active
          sx={{
            paddingTop: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            sx={{
              flexGrow: applicationsFetching ? 1 : 0,
              marginTop: 1.5,
            }}
          >
            {activeTab === ApplicationsTab.Open && (
              <Table
                withDefaultHover
                columns={columns}
                rows={rows}
                isEmpty={!rows.length}
                renderEmptyContent={emptyModalContent}
                isLoading={applicationsFetching}
                emptyContentSxProps={{
                  mx: 0,
                }}
              />
            )}
            {activeTab === ApplicationsTab.Converted && (
              <Table
                hoverStyles={getHoverStyles({
                  iconColor: theme.palette.success.main,
                  hoverColor: theme.palette.success.superLight ?? '',
                })}
                withDefaultHover
                columns={columns}
                isEmpty={!rows.length}
                renderEmptyContent={emptyModalContent}
                rows={rows}
                isLoading={applicationsFetching}
                emptyContentSxProps={{
                  mx: 0,
                }}
              />
            )}
            {activeTab === ApplicationsTab.Rejected && (
              <Table
                hoverStyles={getHoverStyles({
                  iconColor: theme.palette.error.main,
                  hoverColor: theme.palette.error.superLight ?? '',
                })}
                withDefaultHover
                columns={columns}
                isEmpty={!rows.length}
                renderEmptyContent={emptyModalContent}
                rows={rows}
                isLoading={applicationsFetching}
                emptyContentSxProps={{
                  mx: 0,
                }}
              />
            )}

            {children}
          </Stack>
        </ModalContent>
      </ModalMain>
      {isApplicationManager && (
        <ModalFooter active sx={{ justifyContent: 'flex-start' }}>
          <Button
            startIcon={<PlusIcon />}
            onClick={() => navigate('/applications/new')}
            data-test-id="application-create-button"
          >
            <FormattedMessage id="applications-Create" />
          </Button>
        </ModalFooter>
      )}
    </>
  );
};
