import {
  FilterKeys,
  MAX_PAGE_SIZE,
  Product,
  ProductTypeId,
  SavedFilter,
  useGetProductsListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { FilterAdapter } from '@schooly/components/filters';
import { useCallback, useMemo } from 'react';

// This adapter resolves the problem of product_type_ids field between BE and FE
// BE handles it as list of IDs, but on FE we need to convert it to list of ProductTypeId objects
// incoming - BE filter data
// normalized -  normalized data for FE filters
export const usePayableFeesFilterAdapter = (): FilterAdapter => {
  const { schoolId } = useAuth();
  const { data } = useGetProductsListQuery({ schoolId: schoolId || '', pageSize: MAX_PAGE_SIZE });
  const entries = useMemo(
    () => data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Product[]) ?? [],
    [data],
  );

  const func = useCallback(
    (
      filter: Omit<SavedFilter['filter'], FilterKeys.ProductType> & {
        product_type_ids: string[];
      },
    ) => {
      const productTypeIDs = filter.product_type_ids;

      return {
        incoming: filter,
        normalized: {
          ...filter,
          product_type_ids: productTypeIDs?.map((id) => {
            const product = entries.find((p) => p.types.some((t) => t.id === id));
            return new ProductTypeId(id, product?.id || '');
          }),
        },
      };
    },
    [entries],
  );

  // here we force type to FilterAdapter because FilterAdapter works only with FilterKeys, but we need to convert ProductTypeId to string betweeen BE and FE
  return func as unknown as FilterAdapter;
};
