/*
 * Hook fixes broken cases when a user clicks on an applink in an email message.
 * The applink allows users to tap a link to website and get seamlessly
 * redirected to installed app without going through browser.
 *
 * Broken cases:
 * 1. The email client broke the link.
 * 2. The domain didn't pass the verification process
 *
 * As a solution to the problem:
 * we simply redirect the misguided user to the link that contains the application schema.
 *
 * Currently, the hook only works with the two pathnames that exist
 * in Schooly emails: '/accept-invite', '/reset-password',
 *
 * If the application scheme is not detected then you will see an error in browser console:
 * «Failed to launch 'schooly://accept-invite?invite_id={inviteId}'
 *  because the scheme does not have a registered handler.»
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { validate } from 'uuid';

const MESSAGES_ROUTE = 'messages';

const commonAppLinks = new Set([
  '/accept-invite',
  '/reset-password',
  '/auth/accept-invite',
  '/auth/reset-password',
  '/workspace/inbox/notifications',
  '/workspace/inbox/messages',
]);
const APP_SCHEME = 'schooly';

const isAppLink = (pathname: string) => {
  if (commonAppLinks.has(pathname)) {
    return true;
  }

  const isOneMessage = () => {
    const splitPath = pathname.split('/'); // there is waiting for  '/messages/{message_id}' -> ['','messages','{message_id}']
    return splitPath.length === 3 && splitPath[1] === MESSAGES_ROUTE && validate(splitPath[2]);
  };

  return isOneMessage();
};

export const useAppLinkRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const isMobile = isIOS() || isAndroid();

    if (isMobile && isAppLink(location.pathname)) {
      if (typeof window !== `undefined`) {
        window.location.assign(`${APP_SCHEME}:/${location.pathname}${location.search}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const isIOS = (): boolean => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  const userAgent = getUserAgent();
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const isAndroid = (): boolean => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  const userAgent = getUserAgent();
  return /android/i.test(userAgent);
};

export const getUserAgent = () => navigator.userAgent;
