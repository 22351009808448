import {
  AnnualPlan,
  AnnualPlanRecord,
  AnnualPlanRecordEvent,
  AnnualPlanRecordTypes,
  ApiError,
  Event,
  EventsStatuses,
  GET_ANNUAL_PLAN_QUERY,
  getEvent,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { EventsInvite } from '@schooly/constants';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form-lts';

import { useEventActions } from '../../../../../context/events/useEventActions';
import { usePeriodValidation } from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateRecordContext } from '../WithAnnualPlannerCreate';

const isAnnualPlanRecordEvent = (record: AnnualPlanRecord): record is AnnualPlanRecordEvent => {
  return [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(record.type);
};

export interface AnnualPlannerCreateEventContextProps {
  isSavingEvent: boolean;
  canDeleteEvent: boolean;
  deleteEvent: () => Promise<void>;
  isDeletingEvent: boolean;
  canCancelEvent: boolean;
  cancelEvent: () => Promise<void>;
  isCancellingEvent: boolean;
  submitEvent: SubmitHandler<AnnualPlannerCreateForm & { withNotifications?: boolean }>;
  addEventToQueryCache: (data: Event) => void;
  updateEventInQueryCache: ({
    id,
    data,
    withFollowing,
    eventStatus,
  }: {
    id: Event['id'];
    data: Partial<AnnualPlanRecordEvent>;
    withFollowing?: boolean;
    eventStatus: EventsStatuses;
  }) => void;
  updateBulkEventsInQueryCache: (
    ids: Array<Event['id']>,
    data: Partial<AnnualPlanRecordEvent>,
  ) => void;
  deleteEventFromQueryCache: ({
    id,
    withFollowing,
    eventStatus,
  }: {
    id: Event['id'];
    withFollowing?: boolean;
    eventStatus: EventsStatuses;
  }) => void;
  deleteBulkEventsFromQueryCache: (ids: Array<Event['id']>) => void;
}

export const AnnualPlannerCreateEventContext = createContext<AnnualPlannerCreateEventContextProps>({
  isSavingEvent: false,
  canDeleteEvent: false,
  deleteEvent: async () => {},
  isDeletingEvent: false,
  canCancelEvent: false,
  cancelEvent: async () => {},
  isCancellingEvent: false,
  submitEvent: () => {},
  addEventToQueryCache: () => {},
  updateEventInQueryCache: () => {},
  updateBulkEventsInQueryCache: () => {},
  deleteEventFromQueryCache: () => {},
  deleteBulkEventsFromQueryCache: () => {},
});

export const WithAnnualPlannerCreateEvent: FC<PropsWithChildren> = ({ children }) => {
  const { record, close } = useContext(AnnualPlannerCreateRecordContext);

  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { validatePastDate } = usePeriodValidation();

  const eventActions = useEventActions();

  const [fetchingOriginal, setFetchingOriginal] = useState(false);

  const queryClient = useQueryClient();

  const isPublished =
    (record?.details as AnnualPlanRecordEvent)?.status === EventsStatuses.Published;
  const isActive = record && isPublished && validatePastDate(record?.start);

  const canDeleteEvent = Boolean(
    record &&
      [AnnualPlanRecordTypes.HOLIDAY, AnnualPlanRecordTypes.EVENT].includes(record.type) &&
      !isPublished &&
      !isActive,
  );
  const canCancelEvent = Boolean(
    record &&
      [AnnualPlanRecordTypes.HOLIDAY, AnnualPlanRecordTypes.EVENT].includes(record.type) &&
      isPublished,
  );

  const addEventToQueryCache = useCallback<
    AnnualPlannerCreateEventContextProps['addEventToQueryCache']
  >(
    (data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: [
            ...input.records,
            {
              type:
                data.event_type === 'holiday'
                  ? AnnualPlanRecordTypes.HOLIDAY
                  : AnnualPlanRecordTypes.EVENT,
              id: data.id,
              title: data.title,
              description: data.description,
              start: data.start,
              end: data.end,
              invitee_type: data.invitee_type,
              status: data.event_status,
              recurring_state: data.recurring_state,
              can_be_edited: data.can_be_edited,
            },
          ],
        };
      });
    },
    [queryClient],
  );

  const updateEventInQueryCache = useCallback<
    AnnualPlannerCreateEventContextProps['updateEventInQueryCache']
  >(
    ({ id, data, withFollowing, eventStatus }) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) return input;

        const index = input.records.findIndex(
          (record) => isAnnualPlanRecordEvent(record) && record.id === id,
        );
        const record = input.records[index];
        const recurrenceId = isAnnualPlanRecordEvent(record)
          ? record.recurring_state?.recurrence_id
          : undefined;

        const updatedRecords = input.records.map((record, idx) => {
          const shouldUpdate = withFollowing
            ? isAnnualPlanRecordEvent(record) &&
              idx >= index &&
              record.recurring_state?.recurrence_id &&
              record.recurring_state?.recurrence_id === recurrenceId &&
              record.status === eventStatus
            : isAnnualPlanRecordEvent(record) && record.id === id;

          return shouldUpdate
            ? {
                ...record,
                ...(data as AnnualPlanRecord),
              }
            : record;
        });

        return {
          ...input,
          records: updatedRecords,
        };
      });
    },
    [queryClient],
  );

  const updateBulkEventsInQueryCache = useCallback<
    AnnualPlannerCreateEventContextProps['updateBulkEventsInQueryCache']
  >(
    (ids, data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: input.records.map((record) =>
            [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(record.type) &&
            ids.includes(record.id)
              ? {
                  ...record,
                  ...(data as AnnualPlanRecord),
                }
              : record,
          ),
        };
      });
    },
    [queryClient],
  );

  const deleteEventFromQueryCache = useCallback<
    AnnualPlannerCreateEventContextProps['deleteEventFromQueryCache']
  >(
    ({ id, withFollowing, eventStatus }) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        const index = input.records.findIndex(
          (record) =>
            [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(record.type) &&
            record.id === id,
        );

        const deletingRecord = input.records[index] as AnnualPlanRecordEvent;

        const recordsStart = input.records.slice(0, index);
        let recordsEnd = input.records.slice(index + 1);

        if (withFollowing) {
          recordsEnd = recordsEnd.filter(
            (record) =>
              !(
                [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(
                  record.type,
                ) &&
                (record as AnnualPlanRecordEvent).recurring_state?.recurrence_id &&
                (record as AnnualPlanRecordEvent).recurring_state?.recurrence_id ===
                  deletingRecord.recurring_state?.recurrence_id &&
                (record as AnnualPlanRecordEvent).status === eventStatus
              ),
          );
        }

        return {
          ...input,
          records: [...recordsStart, ...recordsEnd],
        };
      });
    },
    [queryClient],
  );

  const deleteBulkEventsFromQueryCache = useCallback<
    AnnualPlannerCreateEventContextProps['deleteBulkEventsFromQueryCache']
  >(
    (ids) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: input.records.filter(
            (record) =>
              !(
                [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(
                  record.type,
                ) && ids.includes(record.id)
              ),
          ),
        };
      });
    },
    [queryClient],
  );

  const deleteEvent = useCallback<AnnualPlannerCreateEventContextProps['deleteEvent']>(async () => {
    const details = record?.details as AnnualPlanRecordEvent;

    if (!record || !details.id) {
      return;
    }

    const isConfirmed = await getConfirmation({
      textId:
        record.type === AnnualPlanRecordTypes.HOLIDAY
          ? 'annualPlanner-Popover-Holiday-Delete-Confirmation'
          : 'annualPlanner-Popover-Event-Delete-Confirmation',
      textValues: { name: details.title },
    });

    if (!isConfirmed) {
      return;
    }

    try {
      setFetchingOriginal(true);

      const data = await getEvent({ eventId: record.id });

      setFetchingOriginal(false);

      if (!data) {
        return;
      }

      await eventActions.deleteEvent(data, {
        onSuccess: () => {
          close();
        },
      });
    } catch (error) {
      showError(error as ApiError);
    }
  }, [close, eventActions, getConfirmation, record, showError]);

  const cancelEvent = useCallback<AnnualPlannerCreateEventContextProps['cancelEvent']>(async () => {
    if (!record) return;

    try {
      setFetchingOriginal(true);

      const data = await getEvent({ eventId: record.id });

      setFetchingOriginal(false);

      if (!data) return;

      const res = await eventActions.cancelEvent(data, data?.recurring_state?.following_count ?? 0);
      if (res) close();
    } catch (error) {
      showError(error as ApiError);
    }
  }, [close, eventActions, record, showError]);

  const submitEvent = useCallback<AnnualPlannerCreateEventContextProps['submitEvent']>(
    async ({ withNotifications, ...values }) => {
      if (!values.event?.name) {
        return;
      }

      if (values.originId) {
        try {
          setFetchingOriginal(true);

          const data = await getEvent({ eventId: values.originId });

          setFetchingOriginal(false);

          if (!data) {
            return;
          }

          eventActions.updateEvent(
            {
              ...data,
              start: values.date[0],
              end: values.date[1],
              title: values.event.name,
              originalEvent: data,
              withNotifications,
            },
            {
              onSuccess: () => {
                close();
              },
            },
          );
        } catch (error) {
          showError(error as ApiError);
        }
      } else {
        try {
          eventActions.createEvent(
            {
              start: values.date[0],
              end: values.date[1],
              title: values.event.name,
              description: '',
              criteria: {},
              date_times: [],
              invitee_type:
                values.type === AnnualPlanRecordTypes.HOLIDAY
                  ? EventsInvite.StudentsOnly
                  : undefined,
              event_type: values.type === AnnualPlanRecordTypes.HOLIDAY ? 'holiday' : 'default',
              recurring_state: null,
              withNotifications,
            },
            {
              onSuccess: () => {
                close();
              },
            },
          );
        } catch (error) {
          showError(error as ApiError);
        }
      }
    },
    [close, eventActions, showError],
  );

  const value = useMemo<AnnualPlannerCreateEventContextProps>(
    () => ({
      isSavingEvent: fetchingOriginal || eventActions.creating || eventActions.updating,
      canDeleteEvent,
      deleteEvent,
      isDeletingEvent: fetchingOriginal || eventActions.deleting,
      canCancelEvent,
      cancelEvent,
      isCancellingEvent: fetchingOriginal || eventActions.changingStatus,
      submitEvent,
      addEventToQueryCache,
      updateEventInQueryCache,
      updateBulkEventsInQueryCache,
      deleteEventFromQueryCache,
      deleteBulkEventsFromQueryCache,
    }),
    [
      fetchingOriginal,
      eventActions.creating,
      eventActions.updating,
      eventActions.deleting,
      eventActions.changingStatus,
      canDeleteEvent,
      deleteEvent,
      canCancelEvent,
      cancelEvent,
      submitEvent,
      addEventToQueryCache,
      updateEventInQueryCache,
      updateBulkEventsInQueryCache,
      deleteEventFromQueryCache,
      deleteBulkEventsFromQueryCache,
    ],
  );

  return (
    <AnnualPlannerCreateEventContext.Provider value={value}>
      {children}
    </AnnualPlannerCreateEventContext.Provider>
  );
};

export const useAnnualPlannerCreateEvent = () => {
  return useContext(AnnualPlannerCreateEventContext);
};
