import { Box, Button, Card, IconButton, Popover, PopoverProps, Stack } from '@mui/material';
import { AnnualPlanRecordTypes, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { CloseIcon, ExpandIcon, Spin } from '@schooly/style';
import { format, parse } from 'date-fns';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { useAnnualPlanner } from '../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../scheme';
import { getRecordFormData } from '../utils';
import { AnnualPlannerCreatePopoverContent } from './AnnualPlannerCreatePopoverContent';
import { useAnnualPlannerCreateRecord } from './WithAnnualPlannerCreate';

export interface AnnualPlannerCreatePopoverProps extends Omit<PopoverProps, 'open'> {}

export const AnnualPlannerCreatePopover: FC<AnnualPlannerCreatePopoverProps> = (props) => {
  const { schoolYear } = useAnnualPlanner();
  const {
    isOpen,
    anchorEl,
    date,
    setDate,
    setType,
    record,
    expandedData,
    setExpandedData,
    close,
    typeOptions,
    canDelete,
    deleteRecord,
    renderDeleteButton,
    submit,
    isSaving,
    isDeleting,
    disableSave,
  } = useAnnualPlannerCreateRecord();
  const form = useForm<AnnualPlannerCreateForm>();

  const formType = form.watch('type');
  const [formDateStart, formDateEnd] = form.watch('date') || [];

  useEffect(() => {
    if (!formDateStart) {
      return;
    }

    setDate((date) => {
      const dateStr =
        [
          date?.[0] ? format(date[0], DEFAULT_DATE_FORMAT_FNS) : '',
          date?.[1] ? format(date[1], DEFAULT_DATE_FORMAT_FNS) : '',
        ].join(',') ?? '';
      const formDataStr = [formDateStart, formDateEnd ?? ''].join(',') ?? '';

      if (dateStr === formDataStr) {
        return date;
      }

      return [
        parse(formDateStart, DEFAULT_DATE_FORMAT_FNS, new Date()),
        ...(formDateEnd ? [parse(formDateEnd, DEFAULT_DATE_FORMAT_FNS, new Date())] : []),
      ];
    });
  }, [formDateEnd, formDateStart, setDate]);

  useEffect(() => {
    setType((type) => (formType !== type ? formType : type));
  }, [formType, setType]);

  useEffect(() => {
    if (isOpen) {
      // set basic initial data on modal open
      // push to the end of the event loop to prevent possible react-hook-form race
      setTimeout(() => {
        if (record) {
          form.reset(
            getRecordFormData(
              { ...record, ...(date?.[0] ? { start: date[0], end: date[1] ?? date[0] } : {}) },
              schoolYear!,
            ),
          );
        } else {
          switch (formType) {
            case AnnualPlanRecordTypes.ASSESSMENT:
            case AnnualPlanRecordTypes.REPORT:
              form.reset({
                type: formType,
                date: date ? [format(date[0], DEFAULT_DATE_FORMAT_FNS)] : [],
              });
              break;
            case AnnualPlanRecordTypes.EVENT:
            case AnnualPlanRecordTypes.HOLIDAY:
            default:
              form.reset({
                type: formType,
                date: date
                  ? ([
                      format(date[0], DEFAULT_DATE_FORMAT_FNS),
                      date[1] ? format(date[1], DEFAULT_DATE_FORMAT_FNS) : undefined,
                    ].filter(Boolean) as [string, string])
                  : [],
              });
              break;
          }
        }
      }, 0);
    } else {
      // drop off the form on modal close
      form.reset({ type: undefined });
    }
  }, [date, form, isOpen, record, schoolYear, formType]);

  // No available types means no permissions
  if (!typeOptions.length) {
    return null;
  }

  return (
    <Popover
      open={isOpen && !expandedData}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {
        /*
         * Should implicitly unmount the form on popover close as the MUI will unmount it
         * asynchronously which might emit uncaught errors out of the react-hook-form internals
         */
        isOpen && !expandedData && (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(submit)}>
              <Card sx={{ width: 540, p: 2 }}>
                <Stack direction="row" justifyContent="flex-end" gap={2.5} mb={2}>
                  {formType && (
                    <IconButton onClick={() => setExpandedData(form.getValues())} inverse>
                      <ExpandIcon />
                    </IconButton>
                  )}

                  <IconButton onClick={close} inverse>
                    <CloseIcon />
                  </IconButton>
                </Stack>

                <AnnualPlannerCreatePopoverContent />

                {record ? (
                  <Stack direction="row" justifyContent="space-between" mt={2}>
                    {canDelete ? (
                      renderDeleteButton({
                        disabled: disableSave || isDeleting || isSaving,
                        isDeleting,
                        onClick: deleteRecord,
                      })
                    ) : (
                      <Box />
                    )}
                    <Button
                      type="submit"
                      startIcon={isSaving ? <Spin /> : undefined}
                      disabled={disableSave || isDeleting || isSaving}
                    >
                      <FormattedMessage id="action-Save" />
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Button
                      type="submit"
                      startIcon={isSaving ? <Spin /> : undefined}
                      disabled={disableSave || isSaving}
                    >
                      <FormattedMessage id="action-Create" />
                    </Button>
                  </Stack>
                )}
              </Card>
            </form>
          </FormProvider>
        )
      }
    </Popover>
  );
};
