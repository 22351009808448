import { Event, EventsStatuses, useGetEventQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { isDateInPast } from '@schooly/utils/date';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEventActions, UseEventActionsReturn } from './useEventActions';
import { useEventNotifications } from './useEventNotifications';

export type EventFormCriteria = Omit<Event['criteria'], 'groups'> & {
  group?: Event['criteria']['groups'];
};

export interface EventForm extends Omit<Event, 'invitee_type' | 'criteria' | 'event_status'> {
  invitee_type?: Event['invitee_type'];
  criteria: EventFormCriteria;
}

export const EventContext = createContext<EventContextProps>({
  event: undefined,
  id: undefined,
  fetching: false,
  isStarted: false,
  isEnded: false,
  isActive: false,
  canEdit: false,
  canView: false,
  canCreate: false,

  actions: {
    creating: false,
    updating: false,
    publishing: false,
    deleting: false,
    changingStatus: false,

    currentStatus: undefined,
    setCurrentStatus: () => undefined,

    getEventStatus: () => ({
      isStarted: false,
      isEnded: false,
      isActive: false,
      isPublished: false,
      hasStartDateError: false,
    }),

    updateList: () => {},

    createSignUpForEvent: async () => undefined,
    createEvent: async () => undefined,
    updateEvent: async () => undefined,
    saveAndPublishEvent: async () => undefined,
    deleteEvent: async () => undefined,
    submitAction: async () => undefined,
    cancelEvent: async () => false,
    publishEvent: async () => false,
  },
});

export interface EventContextProps {
  event?: Event;
  id?: string;
  fetching: boolean;
  isStarted: boolean;
  isEnded: boolean;
  isActive: boolean;
  canEdit: boolean;
  canView: boolean;
  canCreate: boolean;

  actions: UseEventActionsReturn;
}

EventContext.displayName = 'EventContext';

export function normalizeEvent(event: Event, status?: Event['event_status']): Event {
  let eventStatus = status ? status : event.event_status;

  const endTime = event.date_times[event.date_times.length - 1]?.[1];

  const dateInPast = isDateInPast(event.end, endTime);

  if (dateInPast && event.event_status === EventsStatuses.Published) {
    eventStatus = EventsStatuses.Past;
  }

  return { ...event, event_status: eventStatus };
}

export const WithEvent: FC<PropsWithChildren<{ id?: string; event?: Event }>> = ({
  id: propId,
  event: propEvent,
  children,
}) => {
  const { id: paramId } = useParams<'id'>();
  const id = propId ?? paramId;

  const { permissions } = useAuth();
  const { showEventNotification } = useEventNotifications();
  const eventActions = useEventActions();

  const { data, isFetching: fetching } = useGetEventQuery(
    {
      eventId: id ?? '',
    },
    {
      enabled: Boolean(!propEvent && id),
      refetchOnMount: 'always',
      onSuccess: (e) => {
        eventActions.setCurrentStatus(e.event_status);
      },
    },
  );

  useEffect(() => {
    if (propEvent) {
      eventActions.setCurrentStatus(propEvent.event_status);
    }
  }, [eventActions, propEvent]);

  const event = propEvent ?? data;

  const currentEvent = useMemo(
    () => (event ? normalizeEvent(event, eventActions.currentStatus) : event),
    [event, eventActions.currentStatus],
  );

  const canEdit =
    Boolean(event?.can_be_edited) &&
    currentEvent?.event_status !== EventsStatuses.Past &&
    currentEvent?.event_status !== EventsStatuses.Canceled;

  const canView = permissions.includes('event_viewer');
  const canCreate = permissions.includes('event_creator') || permissions.includes('event_manager');

  const { isActive, isStarted, isEnded } = useMemo(
    () => eventActions.getEventStatus(event),
    [event, eventActions],
  );

  const value = {
    id,
    event: currentEvent,
    fetching,
    isStarted,
    isEnded,
    isActive,
    canEdit,
    canView,
    canCreate,
    showEventNotification,

    actions: eventActions,
  };

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
};

export const useEvent = () => {
  return useContext(EventContext);
};
