import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  FilterSection,
  GetSignUpsListQueryFilters,
  SIGN_UPS_FILTER_KEYS,
  SignUpStatuses,
  SignUpType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DateRangeDropdown,
  EventsParticipationOptions,
  ExpandedSelectFilter,
  FilterDropdown,
  FiltersContainer,
  getParticipantLabel,
  getSignUpsStatusLabel,
  getSignUpsTypeLabel,
  MoreButton,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  SignUpsStatusOptions,
  SignUpsTypeOptions,
} from '@schooly/components/filters';
import { EventsInvite } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../hooks/useSchoolYears';

export interface SignUpsFiltersProps {
  schoolId: string;
  filters: GetSignUpsListQueryFilters;
  defaultFilters: GetSignUpsListQueryFilters;
  defaultUserFilters: GetSignUpsListQueryFilters;
  onSetFilters: (v: GetSignUpsListQueryFilters) => void;
  notActualInitialDate?: boolean;
}

export const SignUpsFilters: FC<SignUpsFiltersProps> = ({
  defaultFilters,
  defaultUserFilters,
  onSetFilters,
  filters: actualFilters,
  schoolId,
  notActualInitialDate,
}) => {
  const { $t } = useIntl();
  const { defaultValidity } = useSchoolYears();
  const [dateChanged, setDateChanged] = useState(false);
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetSignUpsListQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const handleApply = useMemo(() => {
    if (
      !SIGN_UPS_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      })
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
    };
  }, [onSetFilters, actualFilters, draftFilters]);

  const {
    onSetDate,
    onSelectStatus,
    onClearStatus,
    onSelectType,
    onClearType,
    onSelectParticipation,
    onClearParticipation,
  } = useMemo(() => {
    return {
      onSetDate: (v: [Date, Date]) =>
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        }),

      onSelectStatus: (id: SignUpStatuses) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.SignUpStatus]: filters[FilterKeys.SignUpStatus]?.includes(id)
            ? filters[FilterKeys.SignUpStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.SignUpStatus] || []), id],
        })),

      onClearStatus: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.SignUpStatus]: [],
        })),

      onSelectType: (id: SignUpType) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.SignUpType]: filters[FilterKeys.SignUpType]?.includes(id)
            ? filters[FilterKeys.SignUpType]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.SignUpType] || []), id],
        })),

      onClearType: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.SignUpType]: [],
        })),

      onSelectParticipation: (id: EventsInvite) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.InviteType]: filters[FilterKeys.InviteType]?.includes(id)
            ? filters[FilterKeys.InviteType]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.InviteType] || []), id],
        })),

      onClearParticipation: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.InviteType]: [],
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
  }, [defaultUserFilters]);

  const filtersDate = draftFilters.date;
  const filtersStatus = draftFilters.sign_up_status;
  const filtersType = draftFilters.sign_up_type;
  const filtersParticipation = draftFilters.invite_type;

  const dateLabel = $t({ id: 'schoolProperty-DateAndPeriod' });
  const statusLabel = $t({ id: 'eventSignUps-SignUpStatus' });
  const typeLabel = $t({ id: 'eventSignUps-SignUpType' });
  const participationLabel = $t({ id: 'eventSignUps-Participation' });

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.SignUps}
        filters={draftFilters}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(SIGN_UPS_FILTER_KEYS, v));
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          onSetDate={onSetDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultValidity}
          dateChanged={dateChanged}
          notActualInitialDate={notActualInitialDate}
          schoolYearFromToday
        />
      )}

      {filtersStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.SignUpStatus)}
          label={statusLabel}
          tags={(open) =>
            filtersStatus.map((status) => (
              <TagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                onClick={open}
                label={$t({ id: getSignUpsStatusLabel(status) })}
              />
            ))
          }
        >
          {(onClose) => (
            <ExpandedSelectFilter
              selected={filtersStatus}
              onSelect={onSelectStatus}
              onClose={onClose}
              onClear={onClearStatus}
              options={SignUpsStatusOptions}
              getLabel={(s) => $t({ id: getSignUpsStatusLabel(s) })}
            />
          )}
        </FilterDropdown>
      )}

      {filtersType && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.SignUpType)}
          label={typeLabel}
          tags={(open) =>
            filtersType.map((type) => (
              <TagSelect
                key={type}
                sx={{ maxWidth: 200 }}
                onClick={open}
                label={$t({ id: getSignUpsTypeLabel(type) })}
              />
            ))
          }
        >
          {(onClose) => (
            <ExpandedSelectFilter
              selected={filtersType}
              onSelect={onSelectType}
              onClose={onClose}
              onClear={onClearType}
              options={SignUpsTypeOptions}
              getLabel={(type) => $t({ id: getSignUpsTypeLabel(type) })}
            />
          )}
        </FilterDropdown>
      )}

      {filtersParticipation && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.InviteType)}
          label={participationLabel}
          tags={(open) =>
            filtersParticipation.map((status) => (
              <TagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                onClick={open}
                label={$t({ id: getParticipantLabel(status) })}
              />
            ))
          }
        >
          {(onClose) => (
            <ExpandedSelectFilter
              selected={filtersParticipation}
              onSelect={onSelectParticipation}
              onClose={onClose}
              onClear={onClearParticipation}
              options={EventsParticipationOptions}
              getLabel={(s) => $t({ id: getParticipantLabel(s) })}
            />
          )}
        </FilterDropdown>
      )}

      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={[
          { value: FilterKeys.Date, label: dateLabel, required: true },
          { value: FilterKeys.SignUpStatus, label: statusLabel },
          { value: FilterKeys.SignUpType, label: typeLabel },
          { value: FilterKeys.InviteType, label: participationLabel },
        ]}
        selectedOptions={SIGN_UPS_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
