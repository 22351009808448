import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { getUserAgent } from '@schooly/hooks/use-app-link-redirect';
import { CookiesIcon } from '@schooly/style';
import { format } from 'date-fns';
import React, { useCallback, useEffect } from 'react';

import { SCHOOLY_MOBILE_USER_AGENT } from '../../../constants/userAgents';
import { getCookie, setCookie } from '../../../utils/cookie';

const CONSENT_COOKIE_NAME = '__cookie_consent';
const COOKIE_NOTIFICATION_ID = 'cookie';

const CookieNotification: React.FC = () => {
  const { dismissNotification, showNotification } = useNotifications();

  const handleAccept = useCallback(() => {
    const today = format(new Date(), DEFAULT_DATE_FORMAT_FNS);

    setCookie(CONSENT_COOKIE_NAME, today, 365);
    dismissNotification(COOKIE_NOTIFICATION_ID);
  }, [dismissNotification]);

  useEffect(() => {
    if (getCookie(CONSENT_COOKIE_NAME) || getUserAgent() === SCHOOLY_MOBILE_USER_AGENT) {
      return;
    }

    showNotification({
      id: COOKIE_NOTIFICATION_ID,
      icon: <CookiesIcon />,
      textId: 'cookieNotification',
      values: {
        a: (link: string) => (
          <a href="/cookies-policy" target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        ),
      },
      persistent: true,
      actions: [
        { textId: 'action-Accept', handler: handleAccept },
        // { textId: 'action-decline' },
      ],
    });

    return () => dismissNotification(COOKIE_NOTIFICATION_ID);
  }, [handleAccept, showNotification, dismissNotification]);

  return null;
};

export default CookieNotification;
