import { Button } from '@mui/material';
import { AnnualPlanRecordTypes } from '@schooly/api';
import { DeleteIcon, Spin, UnpublishIcon } from '@schooly/style';
import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { AnnualPlannerCreateForm } from '../scheme';
import { AnnualPlannerCreateAssessmentContext } from './forms/WithAnnualPlannerCreateAssessment';
import { AnnualPlannerCreateEventContext } from './forms/WithAnnualPlannerCreateEvent';
import { AnnualPlannerCreateReportContext } from './forms/WithAnnualPlannerCreateReport';
import { AnnualPlannerCreateSchoolPeriodContext } from './forms/WithAnnualPlannerCreateSchoolPeriod';
import { AnnualPlannerCreateRecordContext } from './WithAnnualPlannerCreate';

type RenderDeleteButtonParams = {
  disabled: boolean;
  onClick: () => void;
  isDeleting: boolean;
};

export interface AnnualPlannerCreateRecordGeneralContextProps {
  disableSave: boolean;
  submit: SubmitHandler<AnnualPlannerCreateForm & { withNotifications?: boolean }>;
  isSaving: boolean;
  canDelete: boolean;
  deleteRecord: () => Promise<void>;
  renderDeleteButton: (props: RenderDeleteButtonParams) => ReactNode;
  isDeleting: boolean;
}

export const AnnualPlannerCreateRecordGeneralContext =
  createContext<AnnualPlannerCreateRecordGeneralContextProps>({
    disableSave: false,
    submit: () => {},
    isSaving: false,
    canDelete: false,
    deleteRecord: async () => {},
    renderDeleteButton: () => null,
    isDeleting: false,
  });

export const WithAnnualPlannerCreateGeneral: FC<PropsWithChildren> = ({ children }) => {
  const { record } = useContext(AnnualPlannerCreateRecordContext);
  const {
    submitSchoolPeriod,
    isSavingSchoolPeriod,
    canDeleteSchoolPeriod,
    deleteSchoolPeriod,
    isDeletingSchoolPeriod,
  } = useContext(AnnualPlannerCreateSchoolPeriodContext);
  const {
    submitEvent,
    isSavingEvent,
    canDeleteEvent,
    deleteEvent,
    isDeletingEvent,
    canCancelEvent,
    cancelEvent,
    isCancellingEvent,
  } = useContext(AnnualPlannerCreateEventContext);
  const {
    disableSaveAssessment,
    submitAssessment,
    isSavingAssessment,
    canDeleteAssessment,
    deleteAssessment,
    isDeletingAssessment,
  } = useContext(AnnualPlannerCreateAssessmentContext);
  const { submitReport, isSavingReport, canDeleteReport, deleteReport, isDeletingReport } =
    useContext(AnnualPlannerCreateReportContext);

  const deleteRecord = useCallback<
    AnnualPlannerCreateRecordGeneralContextProps['deleteRecord']
  >(async () => {
    switch (record?.type) {
      case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        return await deleteSchoolPeriod();
      case AnnualPlanRecordTypes.HOLIDAY:
      case AnnualPlanRecordTypes.EVENT:
        return await (canCancelEvent ? cancelEvent() : deleteEvent());
      case AnnualPlanRecordTypes.ASSESSMENT:
        return await deleteAssessment();
      case AnnualPlanRecordTypes.REPORT:
        return await deleteReport();
    }
  }, [
    canCancelEvent,
    cancelEvent,
    deleteAssessment,
    deleteEvent,
    deleteReport,
    deleteSchoolPeriod,
    record?.type,
  ]);

  const submit = useCallback<AnnualPlannerCreateRecordGeneralContextProps['submit']>(
    (values, event) => {
      switch (values.type) {
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
          return submitSchoolPeriod(values, event);
        case AnnualPlanRecordTypes.EVENT:
        case AnnualPlanRecordTypes.HOLIDAY:
          return submitEvent(values, event);
        case AnnualPlanRecordTypes.ASSESSMENT:
          return submitAssessment(values, event);
        case AnnualPlanRecordTypes.REPORT:
          return submitReport(values, event);
      }
    },
    [submitAssessment, submitEvent, submitReport, submitSchoolPeriod],
  );

  const renderDeleteButton = useCallback(
    ({ disabled, onClick, isDeleting }: RenderDeleteButtonParams) => {
      const icon = canCancelEvent ? <UnpublishIcon /> : <DeleteIcon />;
      return (
        <Button
          variant="outlined"
          startIcon={isDeleting ? <Spin /> : icon}
          disabled={disabled}
          onClick={onClick}
        >
          <FormattedMessage id={canCancelEvent ? 'events-action-CancelEvent' : 'action-Delete'} />
        </Button>
      );
    },
    [canCancelEvent],
  );

  const value = useMemo<AnnualPlannerCreateRecordGeneralContextProps>(
    () => ({
      disableSave: disableSaveAssessment,
      isSaving: isSavingSchoolPeriod || isSavingEvent || isSavingAssessment || isSavingReport,
      canDelete:
        canDeleteSchoolPeriod ||
        canDeleteEvent ||
        canCancelEvent ||
        canDeleteAssessment ||
        canDeleteReport,
      deleteRecord,
      renderDeleteButton,
      isDeleting:
        isDeletingSchoolPeriod ||
        isDeletingEvent ||
        isCancellingEvent ||
        isDeletingAssessment ||
        isDeletingReport,
      submit,
    }),
    [
      canCancelEvent,
      canDeleteAssessment,
      canDeleteEvent,
      canDeleteReport,
      canDeleteSchoolPeriod,
      deleteRecord,
      disableSaveAssessment,
      isCancellingEvent,
      isDeletingAssessment,
      isDeletingEvent,
      isDeletingReport,
      isDeletingSchoolPeriod,
      isSavingAssessment,
      isSavingEvent,
      isSavingReport,
      isSavingSchoolPeriod,
      renderDeleteButton,
      submit,
    ],
  );

  return (
    <AnnualPlannerCreateRecordGeneralContext.Provider value={value}>
      {children}
    </AnnualPlannerCreateRecordGeneralContext.Provider>
  );
};
