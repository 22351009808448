import { Components, Paper, Popper, Theme } from '@mui/material';

import { CrossIcon, DropdownIcon } from '../../assets/assets';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    clearIcon: <CrossIcon />,
    popupIcon: <DropdownIcon />,
    PaperComponent: (props) => <Paper elevation={0} {...props} />,
    PopperComponent: (props) => (
      <Popper
        placement="bottom"
        disablePortal={false}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ]}
        {...props}
      />
    ),
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
        top: '50%',
        right: theme.spacing(1.5),

        '& .MuiAutocomplete-popupIndicator': {
          padding: 0,
          margin: 0,
          color: theme.palette.text.secondary,

          '&.MuiAutocomplete-popupIndicatorOpen': {
            color: theme.palette.text.primary,
          },
        },

        '& .MuiAutocomplete-clearIndicator': {
          padding: 0,
          margin: 0,
          visibility: 'unset',
          color: theme.palette.text.secondary,

          '& .MuiSvgIcon-root': {
            fontSize: theme.spacing(2.5),
          },

          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
      },

      '& .MuiFormLabel-root.Mui-disabled': {
        color: theme.palette.text.secondary,
      },

      ...(ownerState.popupOpen
        ? {
            '&& .MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          }
        : {}),

      '&.MuiAutocomplete-hasPopupIcon': {
        '& .MuiInputBase-root': {
          paddingRight: 0,
        },

        // display only one icon
        '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-popupIndicator': {
          display: 'none',
        },
      },
    }),

    paper: ({ theme }) => ({
      marginTop: -3,
      borderRight: theme.mixins.borderControlValue(),
      borderBottom: theme.mixins.borderControlValue(),
      borderLeft: theme.mixins.borderControlValue(),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      ...theme.mixins.dropdownShadow(),
    }),

    loading: ({ theme }) => ({
      padding: theme.spacing(1.5, 1),
    }),

    listbox: ({ theme }) => ({
      padding: theme.spacing(1.5, 1),

      '& .MuiAutocomplete-option': {
        ...theme.typography.h3,

        padding: theme.spacing(0.25, 0.5),
        borderRadius: theme.shape.borderRadiusSm,

        '&:not(:last-of-type)': {
          marginBottom: theme.spacing(0.5),
        },

        '&.Mui-focused, &[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
          backgroundColor: theme.palette.background.default,
        },

        '&[aria-disabled="true"]': {
          opacity: 1,
          pointerEvents: 'auto',
          cursor: 'auto',
          '&:active': {
            pointerEvents: 'none',
          },
        },

        '&[aria-selected="true"]': {
          position: 'relative',

          '&:after': {
            content: '""',
            position: 'absolute',
            display: 'block',
            right: theme.spacing(0.5),
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),

            backgroundImage:
              'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljk5OTk5IDE0LjkxNTJMMy4yOTI5MSA5LjIwODA3QzIuOTAyMzggOC44MTc1NSAyLjkwMjM4IDguMTg0MzggMy4yOTI5MSA3Ljc5Mzg2QzMuNjgzNDMgNy40MDMzMyA0LjMxNjYgNy40MDMzMyA0LjcwNzEyIDcuNzkzODZMOS4wMDAwMyAxMi4wODY4TDE1Ljc5NDMgNS4yOTI4NkMxNi4xODQ4IDQuOTAyMzUgMTYuODE4IDQuOTAyMzYgMTcuMjA4NSA1LjI5MjlDMTcuNTk5IDUuNjgzNDMgMTcuNTk5IDYuMzE2NiAxNy4yMDg0IDYuNzA3MTFMOC45OTk5OSAxNC45MTUyWiIgZmlsbD0iIzI0Mjc1QiIvPgo8L3N2Zz4K)',
          },
        },
      },
    }),

    groupLabel: ({ theme }) => ({
      ...theme.typography.body1,
      padding: 0,
      paddingLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    }),

    groupUl: ({ theme }) => ({
      '& .MuiAutocomplete-option': {
        paddingLeft: theme.spacing(0.5),
      },
    }),
  },
};
