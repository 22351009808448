import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CreateFilterMutationParams,
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GROUPS_ARRANGE_BY_FILTER_KEYS,
  MESSAGE_ARRANGE_BY_FILTER_KEYS,
  PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
  RepetitionType,
  SavedFilter,
  SchoolRelation,
  STAFF_ARRANGE_BY_FILTER_KEYS,
  STUDENTS_ARRANGE_BY_FILTER_KEYS,
  useCreateFilterMutation,
  useRemoveFilterMutation,
  useUpdateFilterMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { PROPERTIES_TEXT_IDS, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import {
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalMain,
  ModalSmall,
  Spin,
  StarIcon,
} from '@schooly/style';
import { format, isToday } from 'date-fns';
import { ChangeEventHandler, ReactNode, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ArrangeBySelect } from '../ArrangeBy/ArrangeBySelect';
import { AssessmentStatusSelectMultiple } from '../AssessmentStatus/AssessmentStatusSelectMultiple';
import { ConductTypeSelectMultiple } from '../ConductType/ConductTypeSelectMultiple';
import { ConductVisibilitySelectMultiple } from '../ConductVisibility/ConductVisibilitySelectMultiple';
import { DateSelect } from '../Date/DateSelect';
import { SchoolPeriodsRangeSelect } from '../Date/SchoolPeriods/SchoolPeriodsRangeSelect';
import { DayPastDueSelectMultiple } from '../DayPastDue/DayPastDueSelectMultiple';
import { DropdownSelectFilter } from '../DropdownSelectFilter';
import {
  EventsParticipationOptions,
  EventsStatusOptions,
  getEventsStatusLabel,
  getParticipantLabel,
} from '../Events/utils';
import { FeeStatusSelectMultiple } from '../FeeStatus/FeeStatusSelectMultiple';
import { FrequencySelectMultiple } from '../Frequency/FrequencySelectMultiple';
import { GenderSelectMultiple } from '../Gender/GenderSelectMultiple';
import { GroupSelectMultiple } from '../Group/GroupSelectMultiple';
import { InviteStatusSelectMultiple } from '../InviteStatus/InviteStatusSelectMultiple';
import { MessageStatusSelectMultiple } from '../MessageStatus/MessageStatusSelectMultiple';
import { NationalitySelectMultiple } from '../Nationality/NationalitySelectMultiple';
import { ProductSelectMultiple } from '../Product/ProductSelectMultiple';
import { AgeGroupSelectMultiple } from '../PropertyType/AgeGroupSelectMultiple';
import { PropertyTypeSelectMultiple } from '../PropertyType/PropertyTypeSelectMultiple';
import { StudentStaffPropertyTypeSelectMultiple } from '../PropertyType/StudentStaffPropertyTypeSelectMultiple';
import { AssessmentRecurrenceSelect } from '../Recurrence/RecurrenceSelect/AssessmentRecurrenceSelect';
import { EventRecurrenceSelect } from '../Recurrence/RecurrenceSelect/EventRecurrenceSelect';
import { ReportSelectMultiple } from '../Report/ReportSelectMultiple';
import { ReportStatusSelectMultiple } from '../ReportStatus/ReportStatusSelectMultiple';
import {
  getSignUpsStatusLabel,
  getSignUpsTypeLabel,
  SignUpsStatusOptions,
  SignUpsTypeOptions,
} from '../SignUps/utils';
import { SubjectSelectMultiple } from '../Subject/SubjectSelectMultiple';
import { UserSelectMultiple } from '../User/UserSelectMultiple';
import { UserRoleSelectMultiple } from '../UserRole/UserRoleSelectMultiple';
import { toggleMultipleValueArrayProperty } from '../utils';
import { FilterAdapter } from './PersonalFiltersDropdown';

const noop = () => {};

type PersonalFilterModalProps<T> = {
  currentUser?: SchoolRelation;
  section: FilterSection;
  relationId: string;
  schoolId: string;
  filterKeys: readonly T[];
  onClose: () => void;
  onApply: (v: SavedFilter) => void;
  filter: Omit<SavedFilter, 'id'> & { id?: string };
  filterAdapter?: FilterAdapter;
  defaultSchoolYear?: DefaultSchoolYear;
  getOptionLabelId?: (o: FilterKeys) => string;
};

type PersonalFilterForm = Omit<SavedFilter, 'id' | 'section' | 'filter'>;

const SECTION_NAME_TEXT_ID: { [k in FilterSection]: string } = {
  [FilterSection.Student]: 'section-Students',
  [FilterSection.Staff]: 'section-Staff',
  [FilterSection.Parent]: 'section-Parents',
  [FilterSection.Group]: 'section-Groups',
  [FilterSection.Assessment]: 'section-Assessments',
  [FilterSection.Message]: 'section-Messages',
  [FilterSection.Report]: 'section-Reports',
  [FilterSection.Attendance]: 'section-Attendance',
  [FilterSection.Conduct]: 'section-Conduct',
  [FilterSection.Events]: 'section-Events',
  [FilterSection.SignUps]: 'section-SignUps',
  [FilterSection.AnnualPlanner]: 'section-AnnualPlanner',
  [FilterSection.PayableFees]: 'section-payableFees',
};

const SECTION_WITH_DATE_RANGE: { [k in FilterSection]: boolean } = {
  [FilterSection.Student]: false,
  [FilterSection.Staff]: false,
  [FilterSection.Parent]: false,
  [FilterSection.Group]: true,
  [FilterSection.Assessment]: true,
  [FilterSection.Message]: true,
  [FilterSection.Report]: true,
  [FilterSection.Attendance]: true,
  [FilterSection.Conduct]: true,
  [FilterSection.Events]: true,
  [FilterSection.SignUps]: true,
  [FilterSection.AnnualPlanner]: false,
  [FilterSection.PayableFees]: true,
};

const SECTION_ARRANGE_BY_OPTIONS: { [k in FilterSection]: readonly FilterKeys[] | null } = {
  [FilterSection.Student]: STUDENTS_ARRANGE_BY_FILTER_KEYS,
  [FilterSection.Staff]: STAFF_ARRANGE_BY_FILTER_KEYS,
  [FilterSection.Parent]: null,
  [FilterSection.Group]: GROUPS_ARRANGE_BY_FILTER_KEYS,
  [FilterSection.Assessment]: null,
  [FilterSection.Message]: MESSAGE_ARRANGE_BY_FILTER_KEYS,
  [FilterSection.Report]: null,
  [FilterSection.Attendance]: null,
  [FilterSection.Conduct]: null,
  [FilterSection.Events]: null,
  [FilterSection.SignUps]: null,
  [FilterSection.AnnualPlanner]: null,
  [FilterSection.PayableFees]: PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
};

export const PersonalFilterModal = <T extends FilterKeys>({
  currentUser,
  filterKeys,
  section,
  schoolId,
  relationId,
  filter: filterData,
  onApply,
  onClose,
  filterAdapter,
  defaultSchoolYear,
  getOptionLabelId,
}: PersonalFilterModalProps<T>) => {
  const { $t } = useIntl();
  const { permissions } = useAuth();

  const { subjects, isLoading: subjectsLoading } = useSubjects(
    { schoolId },
    { refetchOnMount: true },
  );

  const { propertiesMap: studentPropertiesMap, isLoading: studentPropertiesLoading } =
    useSchoolProperties(
      {
        schoolId,
        userType: SchoolUserRole.Student,
      },
      { enabled: true },
    );
  const { propertiesMap: staffPropertiesMap, isLoading: staffPropertiesLoading } =
    useSchoolProperties(
      {
        schoolId,
        userType: SchoolUserRole.Staff,
      },
      { enabled: true },
    );

  const hasSubjects = Boolean(subjects.length && !subjectsLoading);
  const hasHouses = Boolean(studentPropertiesMap.house.length && !studentPropertiesLoading);
  const hasDepartments = Boolean(staffPropertiesMap.department.length && !staffPropertiesLoading);
  const isLoading = subjectsLoading || studentPropertiesLoading || staffPropertiesLoading;

  const { id, name, is_default, filter } = filterData;

  const { getConfirmation } = useConfirmationDialog();
  const [state, setState] = useState<PersonalFilterForm>({
    name,
    is_default,
  });
  const [saveAction, setSaveAction] = useState<'save' | 'saveAndApply'>('save');
  const [filters, setFilters] = useState<SavedFilter['filter']>(() => {
    // Remove initial single date for new filter with "today" date

    if (id) return filter;
    if (SECTION_WITH_DATE_RANGE[section]) return filter;

    const date = filter.date?.[0];

    return {
      ...filter,
      [FilterKeys.Date]: date && isToday(new Date(date)) ? [] : filter.date,
    };
  });

  const { showError } = useNotifications();
  const [nameError, setNameError] = useState<string | null>(null);

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setNameError(null);
    setState((s) => ({ ...s, name: e.target.value }));
  }, []);

  const removeFilterMutation = useRemoveFilterMutation();
  const createFilterMutation = useCreateFilterMutation();
  const updateFilterMutation = useUpdateFilterMutation();

  const handleSave = async (andApply: boolean) => {
    if (!state.name) {
      setNameError($t({ id: 'input-ErrorRequired' }));
      return;
    }

    setSaveAction(andApply ? 'saveAndApply' : 'save');

    const filter = filterAdapter ? filterAdapter(filters).incoming : filters;

    const filterData: CreateFilterMutationParams = {
      schoolId,
      relationId,
      section,
      ...state,
      filter: {
        ...filter,
        arrange_by: filters.arrange_by,
        group_by: filters.group_by,
        show_by_present_absent: filters.show_by_present_absent,
      },
    };

    if (!id) {
      createFilterMutation.mutate(filterData, {
        onError: showError,
        onSuccess: (result) => {
          onClose();
          andApply &&
            result.filter_id &&
            onApply({
              id: result.filter_id,
              ...filterData,
            });
        },
      });

      return;
    }

    updateFilterMutation.mutate(
      { ...filterData, id },
      {
        onError: showError,
        onSuccess: () => {
          onClose();
          andApply &&
            onApply({
              id,
              ...filterData,
            });
        },
      },
    );
  };

  const handleDelete = async () => {
    if (!id) return;

    if (
      !(await getConfirmation({
        textId: 'filter-delete-Confirm',
      }))
    ) {
      return;
    }

    removeFilterMutation.mutate(
      { id, relationId, schoolId },
      {
        onError: showError,
        onSuccess: onClose,
      },
    );
  };

  const onClearFilter = (k: FilterKeys) => () => {
    setFilters((s) => ({ ...s, [k]: [] }));
  };

  const onSetStringFilter =
    (
      k:
        | FilterKeys.AgeGroup
        | FilterKeys.Date
        | FilterKeys.Subject
        | FilterKeys.Group
        | FilterKeys.Report
        | FilterKeys.Staff
        | FilterKeys.House
        | FilterKeys.ConductType
        | FilterKeys.Student
        | FilterKeys.Status
        | FilterKeys.StaffHouse
        | FilterKeys.StaffStatus
        | FilterKeys.StudentHouse
        | FilterKeys.StudentAgeGroup
        | FilterKeys.StudentStatus
        | FilterKeys.Department
        | FilterKeys.Creator,
    ) =>
    <T extends string>(v: T) => {
      setFilters((s) => ({ ...s, [k]: toggleArrayProperty(s[k], v) }));
    };

  const RecurrenceSelectComponent =
    section === FilterSection.Assessment ? AssessmentRecurrenceSelect : EventRecurrenceSelect;

  const renderContent = () => {
    const inputs: ReactNode[] = [];

    for (const filterKey of filterKeys) {
      const commonSelectProps = {
        placeholder: $t({ id: PROPERTIES_TEXT_IDS[filterKey] }),
        onClear: onClearFilter(filterKey),
        key: filterKey,
        requiredLabel: 'optional',
      } as const;

      switch (filterKey) {
        case FilterKeys.Date:
          if (SECTION_WITH_DATE_RANGE[section]) {
            inputs.push(
              <SchoolPeriodsRangeSelect
                key={filterKey}
                schoolId={schoolId}
                defaultSchoolYear={defaultSchoolYear}
                hasManagePermission={permissions.includes('school_admin')}
                onSetDate={([fromDate, toDate]) =>
                  setFilters((s) => ({
                    ...s,
                    [FilterKeys.Date]: [
                      format(fromDate, DEFAULT_DATE_FORMAT_FNS),
                      format(toDate, DEFAULT_DATE_FORMAT_FNS),
                    ],
                  }))
                }
                date={filters[FilterKeys.Date] || []}
                requiredLabel="required"
              />,
            );
            break;
          }

          const singleDate = filters[FilterKeys.Date]?.[0];

          inputs.push(
            <DateSelect
              key={filterKey}
              onSetDate={(date) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.Date]: date ? [format(date, DEFAULT_DATE_FORMAT_FNS)] : [],
                }))
              }
              date={singleDate}
              placeholder={$t({ id: PROPERTIES_TEXT_IDS[filterKey] })}
              requiredLabel="required"
              emptyValue={$t({ id: 'datepicker-Today' })}
              onClear={onClearFilter(FilterKeys.Date)}
              onSetToday={onClearFilter(FilterKeys.Date)}
            />,
          );

          break;

        case FilterKeys.Subject:
          if (!hasSubjects) break;
          const hasOnlyTutorGroupEnabled = !!filters[FilterKeys.OnlyTutorGroups]?.length;

          if (hasOnlyTutorGroupEnabled) {
            inputs.push(
              <Tooltip
                key={`${commonSelectProps.key}_disabled`}
                title={$t({ id: 'groups-GroupSubject-disabledFilter-Hint' })}
                placement="bottom-end"
              >
                <div>
                  <SubjectSelectMultiple
                    disabled
                    schoolId={schoolId}
                    onSelectSubjectId={noop}
                    selectedIds={[]}
                    {...commonSelectProps}
                  />
                </div>
              </Tooltip>,
            );
            break;
          }

          inputs.push(
            <SubjectSelectMultiple
              allowArchived
              schoolId={schoolId}
              onSelectSubjectId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Subject] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Group:
          inputs.push(
            <GroupSelectMultiple
              schoolId={schoolId}
              onSelectGroupId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Group] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Report:
          inputs.push(
            <ReportSelectMultiple
              schoolId={schoolId}
              onSelectReportId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Report] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.EventStatus: {
          inputs.push(
            <DropdownSelectFilter
              getLabel={(s) => $t({ id: getEventsStatusLabel(s) })}
              onSelect={(v) => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.EventStatus]: toggleArrayProperty(s[FilterKeys.EventStatus], v),
                }));
              }}
              selected={filters[FilterKeys.EventStatus] || []}
              options={EventsStatusOptions}
              {...commonSelectProps}
              placeholder={$t({ id: 'events-status' })}
            />,
          );
          break;
        }
        case FilterKeys.SignUpStatus: {
          inputs.push(
            <DropdownSelectFilter
              getLabel={(s) => $t({ id: getSignUpsStatusLabel(s) })}
              onSelect={(v) => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.SignUpStatus]: toggleArrayProperty(s[FilterKeys.SignUpStatus], v),
                }));
              }}
              selected={filters[FilterKeys.SignUpStatus] || []}
              options={SignUpsStatusOptions}
              {...commonSelectProps}
              placeholder={$t({ id: 'eventSignUps-SignUpStatus' })}
            />,
          );
          break;
        }
        case FilterKeys.SignUpType: {
          inputs.push(
            <DropdownSelectFilter
              getLabel={(s) => $t({ id: getSignUpsTypeLabel(s) })}
              onSelect={(v) => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.SignUpType]: toggleArrayProperty(s[FilterKeys.SignUpType], v),
                }));
              }}
              selected={filters[FilterKeys.SignUpType] || []}
              options={SignUpsTypeOptions}
              {...commonSelectProps}
              placeholder={$t({ id: 'eventSignUps-SignUpType' })}
            />,
          );
          break;
        }
        case FilterKeys.InviteType: {
          inputs.push(
            <DropdownSelectFilter
              getLabel={(s) => $t({ id: getParticipantLabel(s) })}
              onSelect={(v) => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.InviteType]: toggleArrayProperty(s[FilterKeys.InviteType], v),
                }));
              }}
              selected={filters[FilterKeys.InviteType] || []}
              options={EventsParticipationOptions}
              {...commonSelectProps}
              placeholder={$t({ id: 'eventSignUps-Participation' })}
            />,
          );
          break;
        }
        case FilterKeys.ConductType:
          inputs.push(
            <ConductTypeSelectMultiple
              schoolId={schoolId}
              onSelectId={onSetStringFilter(filterKey)}
              selectedValue={filters[FilterKeys.ConductType] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.ConductStatus:
          inputs.push(
            <ConductVisibilitySelectMultiple
              onSelectConductVisibility={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.ConductStatus]: toggleArrayProperty(s[FilterKeys.ConductStatus], v),
                }))
              }
              selectedVisibility={filters[FilterKeys.ConductStatus] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.AgeGroup:
          inputs.push(
            <AgeGroupSelectMultiple
              allowArchived
              userRole={
                section === FilterSection.Staff ? SchoolUserRole.Staff : SchoolUserRole.Student
              }
              schoolId={schoolId}
              onSelect={(v) => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(
                    s[FilterKeys.AgeGroup],
                    v,
                  ),
                }));
              }}
              selectedIds={filters[FilterKeys.AgeGroup] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.AssessmentStatus:
          inputs.push(
            <AssessmentStatusSelectMultiple
              onSelectStatus={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.AssessmentStatus]: toggleArrayProperty(
                    s[FilterKeys.AssessmentStatus],
                    v,
                  ),
                }))
              }
              selectedStatuses={filters[FilterKeys.AssessmentStatus] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.House:
          if (!hasHouses) break;
          inputs.push(
            <PropertyTypeSelectMultiple
              allowArchived
              userRole={
                section === FilterSection.Staff ? SchoolUserRole.Staff : SchoolUserRole.Student
              }
              propertyType={SchoolPropertyType.House}
              schoolId={schoolId}
              onSelectId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.House] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.StudentHouse:
          if (!hasHouses) break;
          inputs.push(
            <StudentStaffPropertyTypeSelectMultiple
              propertyType={SchoolPropertyType.House}
              schoolId={schoolId}
              onSelectStaffId={onSetStringFilter(FilterKeys.StaffHouse)}
              onSelectStudentId={onSetStringFilter(FilterKeys.StudentHouse)}
              selectedStudentIds={filters[FilterKeys.StudentHouse] || []}
              selectedStaffIds={filters[FilterKeys.StaffHouse] || []}
              {...commonSelectProps}
              onClear={() => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.StudentHouse]: [],
                  [FilterKeys.StaffHouse]: [],
                }));
              }}
            />,
          );
          break;
        case FilterKeys.StaffHouse:
          // This case is covered by FilterKeys.StudentHouse
          break;
        case FilterKeys.Status:
          inputs.push(
            <PropertyTypeSelectMultiple
              allowArchived
              userRole={
                section === FilterSection.Staff ? SchoolUserRole.Staff : SchoolUserRole.Student
              }
              propertyType={SchoolPropertyType.Status}
              schoolId={schoolId}
              onSelectId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Status] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.StudentStatus:
          inputs.push(
            <StudentStaffPropertyTypeSelectMultiple
              propertyType={SchoolPropertyType.Status}
              schoolId={schoolId}
              onSelectStaffId={onSetStringFilter(FilterKeys.StaffStatus)}
              onSelectStudentId={onSetStringFilter(FilterKeys.StudentStatus)}
              selectedStudentIds={filters[FilterKeys.StudentStatus] || []}
              selectedStaffIds={filters[FilterKeys.StaffStatus] || []}
              {...commonSelectProps}
              onClear={() => {
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.StudentStatus]: [],
                  [FilterKeys.StaffStatus]: [],
                }));
              }}
            />,
          );
          break;
        case FilterKeys.StaffStatus:
          // This case is covered by FilterKeys.StudentStatus
          break;
        case FilterKeys.Department:
          if (!hasDepartments) break;
          inputs.push(
            <PropertyTypeSelectMultiple
              allowArchived
              userRole={SchoolUserRole.Staff}
              propertyType={SchoolPropertyType.Department}
              schoolId={schoolId}
              onSelectId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Department] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Staff:
          inputs.push(
            <UserSelectMultiple
              currentUser={currentUser}
              type="staff"
              schoolId={schoolId}
              onSelectUserId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Staff] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Student:
          inputs.push(
            <UserSelectMultiple
              type="student"
              schoolId={schoolId}
              onSelectUserId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Student] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Creator:
          inputs.push(
            <UserSelectMultiple
              currentUser={currentUser}
              type="staff"
              schoolId={schoolId}
              onSelectUserId={onSetStringFilter(filterKey)}
              selectedIds={filters[FilterKeys.Creator] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.MessagesStatus:
          inputs.push(
            <MessageStatusSelectMultiple
              onSelectMessageStatus={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.MessagesStatus]: toggleArrayProperty(s[FilterKeys.MessagesStatus], v),
                }))
              }
              selectedStatuses={filters[FilterKeys.MessagesStatus] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.ReportStatus:
          inputs.push(
            <ReportStatusSelectMultiple
              onSelectStatus={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.ReportStatus]: toggleArrayProperty(s[FilterKeys.ReportStatus], v),
                }))
              }
              selectedStatuses={filters[FilterKeys.ReportStatus] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Gender:
          inputs.push(
            <GenderSelectMultiple
              onSelectGender={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.Gender]: toggleArrayProperty(s[FilterKeys.Gender], v),
                }))
              }
              selectedGenders={filters[FilterKeys.Gender] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.InviteStatus:
          inputs.push(
            <InviteStatusSelectMultiple
              onSelectStatus={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.InviteStatus]: toggleArrayProperty(s[FilterKeys.InviteStatus], v),
                }))
              }
              selectedStatuses={filters[FilterKeys.InviteStatus] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Nationality:
          inputs.push(
            <NationalitySelectMultiple
              onSelectNationality={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.Nationality]: toggleArrayProperty(s[FilterKeys.Nationality], v),
                }))
              }
              selectedNationalities={filters[FilterKeys.Nationality] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.RecipientType:
          inputs.push(
            <UserRoleSelectMultiple
              options={[SchoolUserRole.Staff, SchoolUserRole.Parent]}
              onSelectUserRole={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.RecipientType]: toggleArrayProperty(s[FilterKeys.RecipientType], v),
                }))
              }
              selectedRoles={filters[FilterKeys.RecipientType] || []}
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.OnlyTutorGroups:
          const isOnlyTutorGroupFilterEnabled = !!filters[FilterKeys.OnlyTutorGroups]?.length;

          inputs.push(
            <FormControlLabel
              key={filterKey}
              sx={{ ml: 0 }}
              control={
                <Switch
                  checked={isOnlyTutorGroupFilterEnabled}
                  onChange={() =>
                    setFilters((s) => ({
                      ...s,
                      [FilterKeys.OnlyTutorGroups]: isOnlyTutorGroupFilterEnabled ? [] : ['1'],
                      [FilterKeys.Subject]: !isOnlyTutorGroupFilterEnabled ? [] : undefined,
                    }))
                  }
                />
              }
              label={
                <Typography
                  variant="h3"
                  color={isOnlyTutorGroupFilterEnabled ? undefined : 'common.grey'}
                >
                  <FormattedMessage id={PROPERTIES_TEXT_IDS[FilterKeys.OnlyTutorGroups]} />
                </Typography>
              }
            />,
          );
          break;
        case FilterKeys.RecurrenceId:
          inputs.push(
            <RecurrenceSelectComponent
              placeholder={$t({
                id:
                  section === FilterSection.Assessment
                    ? 'assessments-recurring'
                    : 'events-recurring',
              })}
              onClear={() => setFilters((s) => ({ ...s, [FilterKeys.RecurrenceId]: undefined }))}
              schoolId={schoolId}
              onSelectRecurrence={(v) =>
                setFilters((s) => ({
                  ...s,
                  [FilterKeys.RecurrenceId]: [v],
                }))
              }
              selectedId={filters[FilterKeys.RecurrenceId]?.[0]}
              filters={{ [FilterKeys.RepetitionType]: [RepetitionType.FirstOfRecurrence] }}
            />,
          );
          break;
        case FilterKeys.Product:
          inputs.push(
            <ProductSelectMultiple
              schoolId={schoolId}
              selectedProductIds={filters[FilterKeys.Product] || []}
              selectedProductTypeIds={filters[FilterKeys.ProductType] || []}
              onSelectProductId={(productId) =>
                setFilters((filters) => ({
                  ...filters,
                  [FilterKeys.ProductType]: (filters[FilterKeys.ProductType] || []).filter(
                    (id) => id.productId !== productId,
                  ),
                  [FilterKeys.Product]: filters[FilterKeys.Product]?.includes(productId)
                    ? filters[FilterKeys.Product]?.filter((ct) => ct !== productId)
                    : [...(filters[FilterKeys.Product] || []), productId],
                }))
              }
              onSelectProductTypeId={(v) =>
                setFilters((filters) => ({
                  ...filters,
                  [FilterKeys.Product]: (filters[FilterKeys.Product] || []).filter(
                    (id) => id !== v.productId,
                  ),
                  [FilterKeys.ProductType]: filters[FilterKeys.ProductType]?.some((p) =>
                    p.isEqual(v),
                  )
                    ? filters[FilterKeys.ProductType]?.filter((p) => !p.isEqual(v))
                    : [...(filters[FilterKeys.ProductType] || []), v],
                }))
              }
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.Frequency:
          inputs.push(
            <FrequencySelectMultiple
              selectedTypes={filters[FilterKeys.Frequency] || []}
              onSelectType={(v) =>
                setFilters((s) => ({
                  ...s,
                  frequency_types: toggleArrayProperty(s[FilterKeys.Frequency], v),
                }))
              }
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.DayPastDue:
          inputs.push(
            <DayPastDueSelectMultiple
              selectedTypes={filters[FilterKeys.DayPastDue] || []}
              onSelectType={(v) =>
                setFilters((s) => ({
                  ...s,
                  day_past_due: toggleArrayProperty(s[FilterKeys.DayPastDue], v),
                }))
              }
              {...commonSelectProps}
            />,
          );
          break;
        case FilterKeys.FeeStatus:
          inputs.push(
            <FeeStatusSelectMultiple
              selectedStatuses={filters[FilterKeys.FeeStatus] || []}
              onSelectStatus={(v) =>
                setFilters((s) => ({
                  ...s,
                  fee_status: toggleArrayProperty(s[FilterKeys.FeeStatus], v),
                }))
              }
              {...commonSelectProps}
            />,
          );
          break;
      }
    }

    const arrangeByOptions = SECTION_ARRANGE_BY_OPTIONS[section];

    if (arrangeByOptions) {
      inputs.push(
        <ArrangeBySelect
          key="arrange_by"
          options={arrangeByOptions}
          selectedOption={filters.arrange_by}
          getOptionLabelId={getOptionLabelId}
          placeholder={$t({ id: 'filter-ArrangeBy' })}
          onClear={() => setFilters((s) => ({ ...s, arrange_by: FilterKeys.Month }))}
          onSelectOption={(v) =>
            setFilters((s) => ({
              ...s,
              arrange_by: v,
            }))
          }
        />,
      );
    }
    if (section === FilterSection.Conduct) {
      const isSelectedGroupBy = filters.group_by === FilterKeys.Student;

      inputs.push(
        <FormControlLabel
          sx={{ ml: 0, mt: 1 }}
          control={
            <Switch
              checked={filters.group_by === FilterKeys.Student}
              onChange={() =>
                setFilters((s) => ({
                  ...s,
                  group_by: isSelectedGroupBy ? undefined : FilterKeys.Student,
                }))
              }
            />
          }
          label={
            <Typography variant="h3" color={isSelectedGroupBy ? undefined : 'common.grey'}>
              <FormattedMessage id="filter-GroupBy" />{' '}
              <FormattedMessage id={PROPERTIES_TEXT_IDS['student']} />
            </Typography>
          }
        />,
      );
    }

    if (section === FilterSection.Attendance) {
      const isSelectedShowByPresentAbsent = !!filters.show_by_present_absent;

      inputs.push(
        <>
          <FormControlLabel
            sx={{ ml: 0, mt: 1 }}
            control={
              <Switch
                checked={isSelectedShowByPresentAbsent}
                onChange={() =>
                  setFilters((s) => ({
                    ...s,
                    show_by_present_absent: !isSelectedShowByPresentAbsent,
                  }))
                }
              />
            }
            label={
              <Typography
                variant="h3"
                color={isSelectedShowByPresentAbsent ? undefined : 'common.grey'}
              >
                <FormattedMessage id="filter-ShowByPresentAbsent" />
              </Typography>
            }
          />
        </>,
      );
    }

    return inputs;
  };

  const isMutating =
    createFilterMutation.isLoading ||
    updateFilterMutation.isLoading ||
    removeFilterMutation.isLoading;

  const isCreatingOrUpdating = createFilterMutation.isLoading || updateFilterMutation.isLoading;

  const isSaving = saveAction === 'save' && isCreatingOrUpdating;
  const isSavingAndApplying = saveAction === 'saveAndApply' && isCreatingOrUpdating;

  return (
    <ModalSmall
      fullWidth
      open
      onClose={onClose}
      sx={{ pointerEvents: isMutating ? 'none' : undefined }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ModalHeader
            active
            title={
              <ModalHeaderInput
                focused={!state.name}
                value={state.name}
                onChange={handleNameChange}
                placeholder={$t({ id: 'filter-save-Name' })}
                error={!!nameError}
                helperText={nameError}
              />
            }
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={2}>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                >
                  <Typography variant="h2">
                    {$t(
                      { id: 'filter-FiltersForPage' },
                      { page: $t({ id: SECTION_NAME_TEXT_ID[section] }) },
                    )}
                  </Typography>
                  <Box
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      px: theme.spacing(2),
                      py: theme.spacing(0.5),
                      border: `1px solid ${theme.palette.common.light3}`,
                    })}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.is_default}
                          onChange={(e) =>
                            setState((s) => ({ ...s, is_default: e.target.checked }))
                          }
                        />
                      }
                      label={$t({ id: 'filter-UseAsMyDefault' })}
                    />
                  </Box>
                </Stack>
                {renderContent()}
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter active sx={{ justifyContent: id ? 'space-between' : 'flex-end' }}>
            {!!id ? (
              <Button
                disabled={isMutating}
                onClick={handleDelete}
                startIcon={removeFilterMutation.isLoading ? <Spin /> : <DeleteIcon />}
                variant="outlined"
              >
                <FormattedMessage id="action-Delete" />
              </Button>
            ) : (
              <Box />
            )}
            <Stack flexDirection="row" gap={2}>
              <Button
                disabled={isMutating}
                onClick={() => handleSave(false)}
                startIcon={isSaving ? <Spin /> : undefined}
                variant="outlined"
              >
                <FormattedMessage id="action-Save" />
              </Button>
              <Button
                disabled={isMutating}
                onClick={() => handleSave(true)}
                sx={(theme) => ({ '.svg-icon': { color: theme.palette.warning.main } })}
                startIcon={isSavingAndApplying ? <Spin /> : <StarIcon />}
              >
                <FormattedMessage id="filter-save-SaveAndApply" />
              </Button>
            </Stack>
          </ModalFooter>
        </>
      )}
    </ModalSmall>
  );
};

function toggleArrayProperty<T>(array: Array<T> | undefined, value: T) {
  if (!array || !array.length) return [value];

  return array.includes(value) ? array.filter((e) => e !== value) : [...array, value];
}
