import { Icon, Stack } from '@mui/material';
import { Event, EventsStatuses, SignUpStatuses, SignUpType } from '@schooly/api';
import { getEventsStatusLabel } from '@schooly/components/filters';
import { HeaderActions } from '@schooly/components/header-actions';
import { EditIcon, PublishIcon, UnpublishIcon } from '@schooly/style';
import { isNotFalse } from '@schooly/utils/predicates';
import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useEvent } from '../../../context/events/WithEvent';

interface EventPreviewActionsProps {
  event: Event;
  isIncomplete?: boolean;
}

export const EventPreviewActions: FC<EventPreviewActionsProps> = ({ event, isIncomplete }) => {
  const {
    isStarted,
    actions: { changingStatus, cancelEvent, publishEvent },
    canEdit,
  } = useEvent();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const isForPublish = event.event_status === EventsStatuses.Draft && !isStarted;
  const followingCount = event?.recurring_state?.following_count;

  const selectActions = useMemo(() => {
    return [
      isForPublish &&
        canEdit && {
          titleTextId: 'events-action-Publish',
          pendingTitle: formatMessage({ id: 'publishing' }),
          icon: <PublishIcon className="reset-svg-currentColor" />,
          handler: () => publishEvent(event, followingCount ?? 0),
        },
      event.event_status === EventsStatuses.Published &&
        canEdit && {
          titleTextId: 'events-action-Cancel',
          icon: <UnpublishIcon />,
          pendingTitle: formatMessage({
            id: 'cancelling',
          }),
          handler: () => cancelEvent(event, followingCount ?? 0),
        },
    ].filter(isNotFalse);
  }, [formatMessage, canEdit, cancelEvent, event, followingCount, isForPublish, publishEvent]);

  const styleType = useMemo(() => {
    if (event.event_status === EventsStatuses.Canceled) {
      return 'error';
    } else if (event.event_status === EventsStatuses.Published) {
      return 'success';
    }

    return undefined;
  }, [event.event_status]);

  const hasNoTimeSlotsForSignUps =
    !event.date_times.length &&
    event.sign_ups?.some((s) => s.status === SignUpStatuses.Draft && s.type === SignUpType.Slots);

  const title = useMemo(() => {
    return isIncomplete
      ? formatMessage({ id: 'status-Draft' })
      : formatMessage({ id: getEventsStatusLabel(event.event_status) });
  }, [formatMessage, event.event_status, isIncomplete]);

  const tooltip = useMemo(() => {
    if (!canEdit || event.event_status === EventsStatuses.Published) return;

    if (isIncomplete) return formatMessage({ id: 'events-IncompleteHint' });

    if (hasNoTimeSlotsForSignUps) {
      return formatMessage({ id: 'events-SignUp-Duration-NoTimeSlots' });
    }

    const isCriteriaEmpty = Object.values(event.criteria).every((v) => !v.length);

    if (isStarted || isCriteriaEmpty) {
      return (
        <Stack gap={1.25}>
          <FormattedMessage
            id={isCriteriaEmpty ? 'events-action-CriteriaIsEmpty' : 'events-action-Started'}
          />
          <Stack
            onClick={() => navigate('edit')}
            direction="row"
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Icon>
              <EditIcon />
            </Icon>
            <FormattedMessage
              id={isCriteriaEmpty ? 'events-action-SelectInvitees' : 'events-action-EditStartDate'}
            />
          </Stack>
        </Stack>
      );
    }
  }, [
    formatMessage,
    canEdit,
    event.criteria,
    event.event_status,
    hasNoTimeSlotsForSignUps,
    isIncomplete,
    isStarted,
    navigate,
  ]);

  return (
    <HeaderActions
      title={title ?? ''}
      tooltipTitle={tooltip}
      actions={selectActions}
      styleType={styleType}
      pending={changingStatus}
      disabled={
        event.event_status === EventsStatuses.Canceled ||
        event.event_status === EventsStatuses.Past ||
        isIncomplete ||
        hasNoTimeSlotsForSignUps ||
        !canEdit
      }
    />
  );
};
