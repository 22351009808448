import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { ApplicationShort, ApplicationStatus, useGetApplicationsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeaderSearchInput } from '@schooly/components/filters';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { CrossIcon, Loading, ModalHeader, ModalLarge } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { ApplicationsTab } from '../../../context/applications/ApplicationsContext';
import { useApplication } from '../../../context/applications/useApplication';
import { ApplicationListContent } from './ApplicationListContent';

const selectedApplicationTabParams: { [key in ApplicationsTab]: ApplicationStatus } = {
  [ApplicationsTab.Open]: 'open',
  [ApplicationsTab.Converted]: 'converted',
  [ApplicationsTab.Rejected]: 'rejected',
};

const PAGE_SIZE = 10;

export const ApplicationListModal: FC = () => {
  const { schoolId = '' } = useAuth();
  const { setActiveTab, fetching, activeTab } = useApplication();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, params, setParams } =
    useGetApplicationsQuery(
      {
        school_id: schoolId,
        status: selectedApplicationTabParams[activeTab],
        page_size: PAGE_SIZE,
      },
      { refetchOnMount: 'always', enabled: !!schoolId },
    );

  const entries = useMemo(
    () =>
      data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as ApplicationShort[]) ??
      [],
    [data?.pages],
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setParams((params) => ({
      ...params,
      status: selectedApplicationTabParams[newValue as ApplicationsTab],
    }));
  };

  const handleQueryChange = useCallback(
    (query: string) =>
      setParams({
        ...params,
        query,
      }),
    [setParams, params],
  );

  return (
    <ModalLarge open>
      <ModalHeader active title={formatMessage({ id: 'applications-Title-plural' })}>
        <IconButton onClick={() => navigate('/students')}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <Stack direction="row" px={2.5} pt={2.5}>
        <Tabs sx={{ height: 36, minHeight: 36 }} value={activeTab} onChange={handleTabChange}>
          <Tab label={formatMessage({ id: 'applications-Status-Open' })} />
          <Tab label={formatMessage({ id: 'applications-Status-Converted' })} />
          <Tab label={formatMessage({ id: 'applications-Status-Rejected' })} />
        </Tabs>
        <Box
          sx={{
            '& .HeaderFilter, .search-wrapper': { maxWidth: '100%' },
            flexGrow: 1,
            pl: 10,
          }}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleQueryChange}
            placeholder={formatMessage({ id: 'people-Search' })}
          />
        </Box>
      </Stack>
      <ApplicationListContent applicationsFetching={fetching || isLoading} applications={entries}>
        {hasNextPage && (
          <Stack py={5}>
            <div ref={loaderRef} />
            <Loading />
          </Stack>
        )}
      </ApplicationListContent>
    </ModalLarge>
  );
};
