import {
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetPayableFeesArrangeBy,
  GetPayableFeesQueryFilters,
  InvoicesReportingDayPastDue,
  PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
  PAYABLE_FEES_QUERY_FILTER_KEYS,
  PayableFeeStatus,
  PaymentFrequencyType,
  ProductTypeId,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DateRangeDropdown,
  DayPastDueExpandedSelect,
  DayPastDueTagSelect,
  FeeStatusExpandedSelect,
  FeeStatusTagSelect,
  FilterDropdown,
  FrequencyExpandedSelect,
  FrequencyTagSelect,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  ProductExpandedSelect,
  ProductTagSelect,
  ProductTypeTagSelect,
} from '@schooly/components/filters';
import { ArrangeByDropdown, FiltersContainer, MoreButton } from '@schooly/components/filters';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { format } from 'date-fns';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { usePayableFeesFilterAdapter } from './utils';

export type PayableFeesFilters = {
  openArrangeBy: () => void;
};
type PayableFeesFiltersProps = {
  arrangeBy: GetPayableFeesArrangeBy | null;
  onSetArrangeBy: (v: GetPayableFeesArrangeBy) => void;
  schoolId: string;
  filters: GetPayableFeesQueryFilters;
  defaultFilters: GetPayableFeesQueryFilters;
  onSetFilters: (v: GetPayableFeesQueryFilters) => void;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetPayableFeesQueryFilters;
  defaultUserArrangeBy: GetPayableFeesArrangeBy | null;
};

export const PayableFeesFiltersComponent = (
  {
    arrangeBy,
    defaultFilters,
    filters: actualFilters,
    schoolId,
    onSetFilters,
    onSetArrangeBy,
    defaultSchoolYear,
    defaultUserFilters,
    defaultUserArrangeBy,
  }: PayableFeesFiltersProps,
  ref: React.ForwardedRef<PayableFeesFilters>,
) => {
  const { $t } = useIntl();

  const [dateChanged, setDateChanged] = useState(false);
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const arrangeByDropdown = useRef<ArrangeByDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetPayableFeesQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();

  const [draftArrangeBy, setDraftArrangeBy] = useState<GetPayableFeesArrangeBy | null>(arrangeBy);

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    switch (v) {
      default:
        return setDraftFilters((filters) => ({
          ...filters,
          [v]: filters[v] !== undefined ? undefined : [],
        }));
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openArrangeBy: () => {
        setTimeout(() => arrangeByDropdown.current?.open(), 300);
      },
    }),
    [],
  );

  useEffect(() => {
    setDraftArrangeBy(arrangeBy);
    setDraftFilters(actualFilters);
  }, [actualFilters, arrangeBy]);

  const handleApply = useMemo(() => {
    // Operator != is used on purpose to properly compare against undefined and null
    // eslint-disable-next-line eqeqeq
    const arrangeByChanged = draftArrangeBy != arrangeBy;
    const filtersChanged = PAYABLE_FEES_QUERY_FILTER_KEYS.some((key) => {
      const draftFiltersForKey = [...(draftFilters[key] || [])];
      const actualFiltersForKey = [...(actualFilters[key] || [])];

      return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
    });

    if (!filtersChanged && !arrangeByChanged) return undefined;

    return () => {
      filtersChanged && onSetFilters(draftFilters);
      arrangeByChanged && draftArrangeBy && onSetArrangeBy(draftArrangeBy);
    };
  }, [onSetFilters, actualFilters, draftFilters, arrangeBy, onSetArrangeBy, draftArrangeBy]);

  const {
    onSetDate,
    onClearProduct,
    onClearDayPastDue,
    onClearFrequency,
    onClearFeeStatus,
    onSelectFeeStatus,
    onSelectDayPastDue,
    onSelectFrequency,
    onSelectProductId,
    onSelectProductTypeId,
  } = useMemo(() => {
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) => {
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        });
      },
      onSelectProductId: (productId: string) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ProductType]: (filters[FilterKeys.ProductType] || []).filter(
            (id) => id.productId !== productId,
          ),
          [FilterKeys.Product]: filters[FilterKeys.Product]?.includes(productId)
            ? filters[FilterKeys.Product]?.filter((ct) => ct !== productId)
            : [...(filters[FilterKeys.Product] || []), productId],
        }));
      },
      onClearProduct: () => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ProductType]: [],
          [FilterKeys.Product]: [],
        }));
      },
      onSelectProductTypeId: (v: ProductTypeId) => {
        setDraftFilters((filters) => {
          return {
            ...filters,
            [FilterKeys.Product]: (filters[FilterKeys.Product] || []).filter(
              (id) => id !== v.productId,
            ),
            [FilterKeys.ProductType]: filters[FilterKeys.ProductType]?.some((p) => p.isEqual(v))
              ? filters[FilterKeys.ProductType]?.filter((p) => !p.isEqual(v))
              : [...(filters[FilterKeys.ProductType] || []), v],
          };
        });
      },
      onSelectFrequency: (v: PaymentFrequencyType) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.Frequency]: filters[FilterKeys.Frequency]?.includes(v)
            ? filters[FilterKeys.Frequency]?.filter((ct) => ct !== v)
            : [...(filters[FilterKeys.Frequency] || []), v],
        }));
      },
      onSelectFeeStatus: (v: PayableFeeStatus) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.FeeStatus]: filters[FilterKeys.FeeStatus]?.includes(v)
            ? filters[FilterKeys.FeeStatus]?.filter((ct) => ct !== v)
            : [...(filters[FilterKeys.FeeStatus] || []), v],
        }));
      },
      onSelectDayPastDue: (v: InvoicesReportingDayPastDue) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.DayPastDue]: filters[FilterKeys.DayPastDue]?.includes(v)
            ? filters[FilterKeys.DayPastDue]?.filter((ct) => ct !== v)
            : [...(filters[FilterKeys.DayPastDue] || []), v],
        }));
      },
      onClearFrequency: clearFilter(FilterKeys.Frequency),
      onClearFeeStatus: clearFilter(FilterKeys.FeeStatus),
      onClearDayPastDue: clearFilter(FilterKeys.FeeStatus),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDateChanged(false);
    setDraftArrangeBy(defaultUserArrangeBy);
    setDraftFilters(defaultFilters);
  }, [defaultFilters, defaultUserArrangeBy]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftArrangeBy(defaultUserArrangeBy);
    setDraftFilters(defaultUserFilters);
  }, [defaultUserArrangeBy, defaultUserFilters]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const filtersDate = draftFilters.date;
  const filtersProduct = draftFilters.product_ids;
  const filtersProductTypes = draftFilters.product_type_ids;
  const filtersDayPastDue = draftFilters.day_past_due;
  const filtersFrequencies = draftFilters.frequency_types;
  const filtersFeeStatus = draftFilters.fee_status;
  // const filtersPayer = draftFilters.payer_ids;

  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const productLabel = $t({ id: 'products-Product' });
  const frequencyLabel = $t({ id: 'frequencies-Frequency' });
  const feeStatusLabel = $t({ id: 'payableFees-status' });
  const dayPastDueLabel = $t({ id: 'payableFees-daysPastDue' });
  // const payerLabel = $t({ id: 'payers-Payer' });

  const filterOptions: MoreButtonOption<keyof GetPayableFeesQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.Product, label: productLabel },
    { value: FilterKeys.Frequency, label: frequencyLabel },
    { value: FilterKeys.DayPastDue, label: dayPastDueLabel },
    { value: FilterKeys.FeeStatus, label: feeStatusLabel },
    // { value: FilterKeys.Payer, label: payerLabel },
  ];

  const filterAdapter = usePayableFeesFilterAdapter();

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        data-test-id={'filter-personal'}
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.PayableFees}
        filters={{ ...draftFilters, arrange_by: draftArrangeBy || undefined }}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(PAYABLE_FEES_QUERY_FILTER_KEYS, v));

          for (const key of PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS) {
            key === v.arrange_by && onSetArrangeBy(key);
          }
        }}
        filterAdapter={filterAdapter}
        getOptionLabelId={(o) => PROPERTIES_TEXT_IDS[o]}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          dateChanged={dateChanged}
          onSetDate={onSetDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}
      {(filtersProduct || filtersProductTypes) && (
        <FilterDropdown
          data-test-id={'filter-product'}
          onClear={() => {
            toggleFiltersVisible(FilterKeys.Product);
            toggleFiltersVisible(FilterKeys.ProductType);
          }}
          label={productLabel}
          tags={(open) => [
            ...(filtersProduct || []).map((id) => (
              <ProductTagSelect sx={{ maxWidth: 200 }} key={id} id={id} onClick={open} />
            )),
            ...(filtersProductTypes || []).map((id) => (
              <ProductTypeTagSelect
                sx={{ maxWidth: 200 }}
                key={id.toString()}
                id={id}
                onClick={open}
              />
            )),
          ]}
        >
          {(onClose) => (
            <ProductExpandedSelect
              schoolId={schoolId}
              selectedProductIds={filtersProduct || []}
              selectedProductTypeIds={filtersProductTypes || []}
              onSelectProductId={onSelectProductId}
              onSelectProductTypeId={onSelectProductTypeId}
              onClose={onClose}
              onClear={onClearProduct}
            />
          )}
        </FilterDropdown>
      )}
      {filtersFrequencies && (
        <FilterDropdown
          data-test-id={'filter-frequencies'}
          onClear={() => toggleFiltersVisible(FilterKeys.Frequency)}
          label={frequencyLabel}
          tags={(open) =>
            filtersFrequencies.map((type) => (
              <FrequencyTagSelect sx={{ maxWidth: 200 }} key={type} type={type} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <FrequencyExpandedSelect
              selectedTypes={filtersFrequencies}
              onSelectType={onSelectFrequency}
              onClose={onClose}
              onClear={onClearFrequency}
            />
          )}
        </FilterDropdown>
      )}
      {filtersDayPastDue && (
        <FilterDropdown
          data-test-id={'filter-frequencies'}
          onClear={() => toggleFiltersVisible(FilterKeys.DayPastDue)}
          label={dayPastDueLabel}
          tags={(open) =>
            filtersDayPastDue.map((type) => (
              <DayPastDueTagSelect sx={{ maxWidth: 200 }} key={type} type={type} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <DayPastDueExpandedSelect
              selectedTypes={filtersDayPastDue}
              onSelectType={onSelectDayPastDue}
              onClose={onClose}
              onClear={onClearDayPastDue}
            />
          )}
        </FilterDropdown>
      )}
      {filtersFeeStatus && (
        <FilterDropdown
          data-test-id={'filter-feeStatus'}
          onClear={() => toggleFiltersVisible(FilterKeys.FeeStatus)}
          label={feeStatusLabel}
          tags={(open) =>
            filtersFeeStatus.map((status) => (
              <FeeStatusTagSelect
                sx={{ maxWidth: 200 }}
                key={status}
                status={status}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <FeeStatusExpandedSelect
              selectedStatuses={filtersFeeStatus}
              onSelectStatus={onSelectFeeStatus}
              onClose={onClose}
              onClear={onClearFeeStatus}
            />
          )}
        </FilterDropdown>
      )}
      {draftArrangeBy !== null && (
        <ArrangeByDropdown
          data-test-id={'filter-arrange-by'}
          ref={(r: any) => (arrangeByDropdown.current = r as ArrangeByDropdown)}
          onSelectOption={setDraftArrangeBy}
          selectedOption={draftArrangeBy}
          options={PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS}
        />
      )}

      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterOptions}
        selectedOptions={PAYABLE_FEES_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        isSelectedArrangeBy={draftArrangeBy !== null}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};

export const PayableFeesFilters = forwardRef(PayableFeesFiltersComponent);
